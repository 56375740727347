import { React } from "react";
import { Link } from 'react-router-dom'
import favGuidePre from '../../assets/favGuidePre.png';
import fishCreekEx from '../../assets/fishCreek.png';
import anini from '../../assets/anini.png';
import manzanita from '../../assets/manzanita.png'
import marathon from '../../assets/marathon.png';
import sierra from '../../assets/SierraCamper.png';
import zion from '../../assets/zionYurt.png';

const WhatWeProvide = () => {

  return (
    <div className="text-white">
      <div className="w-full mainColor">
        <div className="flex flex-wrap md:flex-nowrap md:exampleHeader mainColor md:pt-10 md:w-4/5 m-auto">
          <div className="flex flex-wrap md:flex-nowrap md:block w-full md:w-1/2 my-auto md:pb-16 md:pl-12 md:text-left">
            <h2 className="w-auto m-auto text-5xl md:text-7xl font-bold pt-3 md:pt-0 mb-8 md:mb-4">Guidebook Inspiration,</h2>
            <h3 className="w-11/12 text-2xl md:text-3xl mx-auto md:mx-0 mb-12 py-3">Here are some of our favorite guides that people have created using Vacanze.</h3>
            <Link to='/login' className="relative md:bottom-4 bg-white homeText px-8 py-3 mt-0 mb-8 md:mb-0 md:mt-12 rounded-md font-bold m-auto md:mt-20">Start your journey!</Link>
          </div>
          <div className="w-full pb-5 mt-2 md:mt-0 md:pb-0 md:w-1/2">
          <img className="w-2/3 md:w-1/3 m-auto" src={favGuidePre} alt='img'/>
          </div>
        </div>
        <div className="block mainColor exampleSvg mobileCurve relative z-20 md:z-0">
        <svg className="relative z-40" viewBox="0 0 1440 320"><path fill="#ffffff" fill-opacity="1" d="M0,32L60,32C120,32,240,32,360,32C480,32,600,32,720,48C840,64,960,96,1080,101.3C1200,107,1320,85,1380,74.7L1440,64L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
        </div>

          <article className="bg-white w-full md:mt-16 pt-5 md:pt-8 text-2xl homeText relative z-90">
            <div className="w-4/5 flex flex-wrap md:grid md:grid-cols-3 pb-40 pt-10 m-auto">
            <div className="ml-auto w-full pb-8">
              <p className="font-semibold">Fish Creek Lodge</p>
              <div className="secondColor m-auto mb-4 h-1 w-20"></div>
                <a target='_blank' rel="noreferrer" href="https://vacanze.herokuapp.com/user/63d9d428f366de6c77a3f86c/guide/63e5195afc37c32a6db18017" className="">
                  <img className="w-3/5 m-auto" src={fishCreekEx} alt='img'/>
                </a>
            </div>
            <div className="m-auto w-full pb-8">
              <p className="font-semibold">Sierra Camper Van</p>
              <div className="secondColor m-auto mb-4 h-1 w-20"></div>
                <a target='_blank' rel="noreferrer" href="https://vacanze.herokuapp.com/user/63d1f9a910d91e3807940d29/guide/6436e8585a5a2091cbf2e33a" className="">
                  <img className="w-3/5 m-auto" src={sierra} alt='img'/>
                </a>
              </div>
            
            <div className="mr-auto w-full pb-8">
              <p className="font-semibold">Anini Beach Bungalow</p>
              <div className="secondColor m-auto mb-4 h-1 w-20"></div>
                <a target='_blank' rel="noreferrer" href="https://vacanze.herokuapp.com/user/63d9d428f366de6c77a3f86c/guide/6429d1e99590aabaa72cadae" className="">
                  <img className="w-3/5 m-auto" src={anini} alt='img'/>
                </a>
            </div>
              
              <div className="m-auto w-full pb-8">
              <p className="font-semibold">Manzanita Beach House</p>
              <div className="secondColor m-auto mb-4 h-1 w-20"></div>
                <a target='_blank' rel="noreferrer" href="https://vacanze.herokuapp.com/user/63d1f9a910d91e3807940d29/guide/6439e447740240b031d08b93" className="">
                  <img className="w-3/5 m-auto" src={manzanita} alt='img'/>
                </a>
              </div>
              
              <div className="m-auto w-full pb-8">
              <p className="font-semibold">Zion Yurt</p>
              <div className="secondColor m-auto mb-4 h-1 w-20"></div>
                <a target='_blank' rel="noreferrer" href="https://vacanze.herokuapp.com/user/63d1f9a910d91e3807940d29/guide/6439e8b7740240b031d08bd3" className="">
                  <img className="w-3/5 m-auto" src={zion} alt='img'/>
                </a>
              </div>
              <div className="mr-auto w-full pb-8">
              <p className="font-semibold">Marathon Getaway</p>
              <div className="secondColor m-auto mb-4 h-1 w-20"></div>
                <a target='_blank' rel="noreferrer" href="https://vacanze.herokuapp.com/user/63d1f9a910d91e3807940d29/guide/643ac81f21efbf19ca59f501" className="">
                  <img className="w-3/5 m-auto" src={marathon} alt='img'/>
                </a>
            </div>
            </div>
          </article>
      </div>
    </div>
  )
}

export default WhatWeProvide;