//app, mailer, login, stripe, all have to be switched to production

import './App.css';
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { StoreProvider } from "./utils/GlobalState";
// import { useStoreContext } from './utils/GlobalState';

import ScrollToTop from './utils/scrollToTop';

import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import LoginSignup from './components/home/login';
import Guide from './pages/guide';

import LandingPage from './pages/landingPage'

import GuideInsights from './components/dashboard/guideInsights/guideInsights';
import EditGuide from './components/dashboard/editGuide/editGuide';
import AddGuide from './components/dashboard/addGuide/addGuide';
import AddSubscription from './components/dashboard/subscription/addSubscription';
// import PricingPage from './components/dashboard/subscription/'

import Protected from './utils/Protected';
import Guides from './components/dashboard/guides';
import Subscription from './components/dashboard/subscription';
import Account from './components/dashboard/account';
import Help from './components/dashboard/help';

import HomeDefault from './components/home/homeDefault';
import WhatWeProvide from './components/home/examples';
import HowItWorks from './components/home/howItWorks';
import ContactUs from './components/home/contactUs';
import ChangePassword from './pages/ChangePassword';
import Pricing from './components/home/pricing';

// import Guidebooks from './components/home/guideBooks/index';
import Bnb from './components/home/guideBooks/bnb';
import Businesses from './components/home/guideBooks/businesses';
import Cleaning from './components/home/guideBooks/cleaning';
import CamperVans from './components/home/guideBooks/camperVans';
import Campgrounds from './components/home/guideBooks/campgrounds';
import Destinations from './components/home/guideBooks/destinations';
import Events from './components/home/guideBooks/events';
import Glamping from './components/home/guideBooks/glamping';
import HostsOwners from './components/home/guideBooks/hostsOwners';
import PropertyManagers from './components/home/guideBooks/propertyManagers';
import More from './components/home/guideBooks/more';


import AdminIndex from './components/admin';



const httpLink = createHttpLink({
  // uri: 'mongodb://0.0.0.0:3002/graphql'
  // uri: 'http://localhost:3002/graphql'
  uri: '/graphql'
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('id_token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

// icon src not working for any of the 4 
// const dynamicManifest = {
//   "short_name": "Vacanze",
//   "name": "Vacanze PWA",
//   "background_color": "#ffffff",

//   "start_url": "/",
//   "display": "standalone",
//   "theme_color": "#000000"
// };
// "icons": [
//   {
//     "src": "https://test-simple-file-uploadfddt.resize-files-simplefileupload.com/yY77Xd4YlNz1z9nQcnvZZIbUpTLmlD4pd4-oXVGoWCU/plain/s3://static.files-simplefileupload.com/kq7c6is4jyhiijuoimfxsuls3x90",
//     "sizes": "512x512",
//     "type": "image/png",
//     "purpose": "any"
//   },
//   {
//     "src": "https://test-simple-file-uploadfddt.resize-files-simplefileupload.com/-ADStZITEKMkmDBW8OgbjZmfUAcfdUUAfAdqvKrtxIM/plain/s3://static.files-simplefileupload.com/o39vohqhtgfryyym1r9qw0mp72iu",
//     "sizes": "144x144",
//     "type": "image/png",
//     "purpose": "any"
//   }
// ],

// const manifestURL = window.location.href;
// dynamicManifest.start_url = manifestURL;
// const stringManifest = JSON.stringify(dynamicManifest);
// const blob = new Blob([stringManifest], {type: 'application/javascript'});
// const manifestHref = URL.createObjectURL(blob);
// const manifest = document.getElementById('my-manifest-placeholder');
// manifest.setAttribute('href', manifestHref);


function App() {

return (
  <ApolloProvider client={client}>
  <StoreProvider>
    <div className="App min-h-screen">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} >
            <Route path="*" element={<HomeDefault to="/" replace />} />
            <Route index element={<HomeDefault />} />
            <Route path='home' element={<HomeDefault />} />
            <Route path='examples' element={<WhatWeProvide />} />
            <Route path='how' element={<HowItWorks />} />
            <Route path='about' element={<ContactUs />} />
            <Route path='pricing' element={<Pricing />} />
            <Route path="login" element={<LoginSignup />} />
            <Route path="LandingPage" element={<LandingPage />} />

            {/* <Route path='guidebooks' element={<Guidebooks />}></Route>
             */}
            <Route path='guidebooks' element={<HostsOwners />}></Route>
            <Route path='guidebooks/HostsOwners' element={<HostsOwners />}></Route>
            <Route path='guidebooks/Businesses' element={<Businesses />}></Route>
            <Route path='guidebooks/bnb' element={<Bnb />}></Route>
            <Route path='guidebooks/Cleaning' element={<Cleaning />}></Route>
            <Route path='guidebooks/CamperVans' element={<CamperVans />}></Route>
            <Route path='guidebooks/Campgrounds' element={<Campgrounds />}></Route>
            <Route path='guidebooks/Destinations' element={<Destinations />}></Route>
            <Route path='guidebooks/Events' element={<Events />}></Route>
            <Route path='guidebooks/Glamping' element={<Glamping />}></Route>
            <Route path='guidebooks/PropertyManagers' element={<PropertyManagers />}></Route>
            <Route path='guidebooks/More' element={<More />}></Route>

            <Route
              path="alg"
              element={
                <Protected>
                  <AdminIndex />
                </Protected>}
            />
          </Route>

          

          <Route 
            path="/ChangePassword/:code/:userId" element={<ChangePassword />}></Route>
          
          <Route 
            path="/user/:userId/guide/:guideId" 
            element={<Guide />} 
          />
          
            <Route 
              path="/Dashboard" 
              element={
                <Protected>
                  <Dashboard />
                </Protected>
              }>
                <Route path="*" element={<Guides to="/" replace />} />
                <Route index element={<Guides />} />
                <Route path='guides' element={<Guides />} />
                <Route path='addGuide' element={<AddGuide />} />
                <Route path='subsription' element={<Subscription />} />
                <Route path='addSubsription' element={<AddSubscription />} />
                <Route path='account' element={<Account />} />
                <Route path='help' element={<Help />} />
                <Route path="guide/edit/:guideId" element={<EditGuide />} />
                <Route path="addSubscription" element={<AddSubscription />} />
                <Route path="guideId/insights" element={<GuideInsights />} />
            </Route>
            
            
            
            <Route
              path="/dashboard/addGuide"
              element={
                <Protected>
                  <AddGuide />
                </Protected>}
            />

            
        </Routes>
      </Router>
    </div>
  </StoreProvider>
  </ApolloProvider>
);
}

export default App;
