const calcPrice = (interval, numberOfGuides) => {
  if(numberOfGuides < 1) return 'ERROR: numberOfGuides must be greater than 0'; 
  let price = 0
  if(interval === 'monthly'){
    switch(numberOfGuides){
      case '1':
        price = 8.99
        break;
      case '2': 
        price = 16.89
        break;
      case '3':
        price = 24.48
        break;
      case '4':
        price = 31.47
        break;
      case '5': 
        price = 37.97
        break;
      case '6':
        price = 43.96
        break;
      case '7':
        price = 49.46
        break;
      default:
        price = (49.46 + ((numberOfGuides - 7) * 4.99))
        break;
    }
  }
  else if(interval === 'yearly'){
    switch(numberOfGuides){
      case '1':
        price = 89.00
        break;
      case '2': 
        price = 174.00
        break;
      case '3':
        price = 253.00
        break;
      case '4':
        price = 328.00
        break;
      case '5': 
        price = 397.00
        break;
      case '6':
        price = 462.00
        break;
      case '7':
        price = 521.00
        break;
      default:
        price = (521.00 + ((parseInt(numberOfGuides) - 7) * 50))
        break;
    }
  }
  else {
    return 'incorrect interval passed to calcPrice, interval must equal "monthly" or "yearly".'
  }
  return parseFloat(price.toFixed(2));
}

export default calcPrice;