import React, { useState, useEffect, useRef } from "react";
import { useStoreContext } from '../../../utils/GlobalState';
import { ImArrowLeft } from 'react-icons/im'
import { Link, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { QUERY_GUIDE } from "../../../utils/queries";
import { UPDATE_GUIDE_TITLE, UPDATE_GUIDE_TITLE_POI } from '../../../utils/mutations';
import { SET_GUIDE } from "../../../utils/actions";
import EditAddressComponent from "./mapAndLocations/editAddress";
import PhotoTheme from "./photoTheme";
import EditSection from "./editSection";
import DeleteGuide from "./deleteGuide";
import EditPhone from "./editPhone";

import QRCodeModal from "../guides/qrCode";

function  EditGuide ()  {
  const [state, dispatch] = useStoreContext();
  const { guideId } = useParams();
  const subStatus = state?.user?.subscriptionStatus === 'active';

  let blur = {
    filter: 'blur(0px)'
  }
  subStatus ? (
    blur = { filter: 'blur(0px)' }
  ) : (
    blur = { filter: 'blur(1.7px)',
    pointerEvents: 'none'
    }
  )

  const [activeEdit, setActiveEdit] = useState('theme');

  const { loading, data } = useQuery(QUERY_GUIDE, {
    variables: {
      guideId: guideId
    }
  });

  //SHARE PAGE STUFF
  const [inShare, setInShare] = useState(false);
  let shareUrl = `https://www.vacanzeguides.com/user/${state?.user?._id}/guide/${state?.guide?._id}`
  let createText = `sms://?&body=Check out this guide to ${state?.guide?.name}: ${shareUrl}`
  let createEmail = `mailto:?to=&body=Check out this guide to ${state?.guide?.name}: ${shareUrl}&subject=Check out this guide to ${state?.guide?.name}`

  useEffect(() => {
    if (data) {
      dispatch({
        type: SET_GUIDE,
        guide: data.guide,
      });
      shareUrl = `https://www.vacanzeguides.com/user/${state?.user?._id}/guide/${state?.guide?._id}`;
      createText = `sms://&body=Check out this guide to ${state?.guide?.name}: ${shareUrl}`;
      createEmail = `mailto:?to=&body=Check out this guide to ${state?.guide?.name}: ${shareUrl}&subject=Check out this guide to ${state?.guide?.name}`;
    }
  }, [data, loading, dispatch]);


    //toggle edit title & edit title funciton
    const [toggleEditTitle, setToggleEditTitle] = useState('false');
    const [updateGuideTitle] = useMutation(UPDATE_GUIDE_TITLE);
    const [updateGuideTitlePoi] = useMutation(UPDATE_GUIDE_TITLE_POI); 
    const guideName = useRef();

    const editTitle = async () => {
      const newName = guideName.current.value;
      const mutationResponse = await updateGuideTitle({
        variables: {
          guideId: guideId,
          name: newName
        }
      });

      if (mutationResponse) {
        const oldName = mutationResponse.data.updateGuideTitle.poi[0].name;
        const fullyUpdatedGuide = await updateGuideTitlePoi({
          variables: {
            guideId: guideId,
            oldName: oldName,
            newName: newName
          }
        })
        dispatch({
          type: SET_GUIDE,
          guide: fullyUpdatedGuide.data.updateGuideTitlePoi,
        });
        setToggleEditTitle('false')
      }
    };
    
    
    // QR CODE STUFF
    const [url, setUrl] = useState('');
    const [display, setDisplay] = useState('none');
    const [qrGuide, setQrGuide] = useState();
  
    const setUrlAndDisplay = (guide) => {
      setUrl(`https://www.vacanzeguides.com/user/${state?.user?._id}/guide/${guide._id}`);
      setDisplay('active');
      setInShare(false)
      setQrGuide(guide)
    }

    //popup stuff
    const subPop = (e) => {
      console.log(e.target.parentNode)
      e.target.parentNode.classList.add('hidden');
    }

  return (
    //hardcoded for now but will call the API to display selected info with a map function of the data
    <div className="font w-full">
      <div className="bg-white my-4  rounded-lg shadow max-w-[1400px] m-auto flex flex-wrap">
        <div className="flex flex-wrap w-full md:w-5/6  m-auto p-1 md:p-2 mb:pb-0 border-b-2 border-gray-300">
          <div className="w-full m-auto">
            {toggleEditTitle === 'true' ? 
            <div className="w-full flex justify-around">
                <input ref={guideName} type='text' defaultValue={state?.guide?.name} className='font-semibold text-xl shadow-md border-2 border-gray-100 px-3 rounded-md w-3/4 md:w-2/3 p-1 md:p-2' />
              <button onClick={() => editTitle()} className='mainColor text-white font-bold p-2 rounded-md w-1/4 mb-5 py-3 mx-5'>save changes</button>
            </div>
            :
            <div className="w-full flex">
              <Link to='/dashboard/guides' className="w-1/4"><ImArrowLeft className="text-3xl md:text-5xl"/></Link>
              <h1 className="text-2xl md:text-4xl font-semibold mx-auto w-1/2 md:w-2/3">{state?.guide?.name}</h1>
              <button onClick={() => setToggleEditTitle('true')} className='mainColor text-white font-bold p-1 md:p-2 rounded-md w-1/3 md:w-1/4 py-1 md:py-3 mx-1 md:mx-5'>Change Title 📝</button>
            </div>}
            <div><EditPhone guideId={guideId} guidePhone={state.guide?.contactPhone}/></div>
          </div>
        </div>
        
        <nav className="editNav w-full bg-gray-100 md:mainColor pt-3  pb-0 md:p-5 mb-2 flex flex-wrap justify-between" >
        <Link to={"/user/" + state?.user?._id + "/guide/" + state?.guide?._id} target="blank" id={state?.guide?._id} className="w-full md:w-auto md:my-1 mb-1 md:mb-0  md:py-2  md:px-5 mt-1 md:bg-white md:text-cyan-900 md:mainColor text-black font-bold text-xl md:rounded-xl md:shadow" style={blur}>See Guide</Link>
        {/* <button onClick={() => {setUrlAndDisplay(state?.guide)}} className='w-full md:w-auto md:ml-5 md:h-14 p-2 mt-1 editNavbg homeText whiteText font-bold rounded-xl' style={blur}>See QR Code</button> */}
        {state?.user?.subscriptionStatus === 'active' && (
          <button onClick={() => {setInShare(true)}} className='mb-3 md:mb-0 w-full md:w-auto md:ml-5 py-1 md:px-5 mt-1 md:bg-white md:text-cyan-900 md:mainColor text-black text-xl font-bold rounded-xl md:shadow' style={blur}>Share Guide</button>
        )}
        
          <div className="w-full md:w-2/3 flex flex-wrap justify-around m-auto text-white md:text-2xl font-bold text-black">
            <button onClick={() => setActiveEdit('theme')} className={activeEdit === 'theme' ? "hover-underline-animation border-b-2 border-cyan-900 w-4/5 md:w-auto mb-3 pb-1 md:mb-0 md:pb-0 text-black" : "hover-underline-animation w-full md:w-auto mb-3 pb-1 md:mb-0 md:pb-0 text-black"}>Edit Guide Photos & Styles</button>

            <button onClick={() => setActiveEdit('map')} className={activeEdit === 'map' ? "w-4/5 md:w-auto hover-underline-animation border-b-2 border-cyan-900  mb-3 pb-1 md:mb-0 md:pb-0 text-black" : "hover-underline-animation w-full md:w-auto mb-3 pb-1 md:mb-0 md:pb-0 text-black"}>Edit Map & Address</button>
            
            <button onClick={() => setActiveEdit('info')} className={activeEdit === 'info' ? "hover-underline-animation border-b-2 border-cyan-900  w-4/5 md:w-auto mb-3 pb-1 md:mb-0 md:pb-0 text-black" : "hover-underline-animation w-full md:w-auto mb-3 pb-1 md:mb-0 md:pb-0 text-black"}>Edit Guide Info</button>
          </div>
          <p className="opacity-0 homeText h-0 md:h-auto">see guide</p>
        </nav>

        {activeEdit === 'map' && (
          <EditAddressComponent guideId={guideId} />
        )}
        {activeEdit === 'theme' && (
          <PhotoTheme guideId={guideId} />
        )}
        {activeEdit === 'info' && (
          <EditSection guideId={guideId} />
        )}
      </div>
      
      <DeleteGuide />
      <QRCodeModal  url={url} display={display} setDisplay={setDisplay} guide={qrGuide}/>
      {!subStatus && (
        // <div className="w-80  bg-white border-2 border-gray-500 guideShadow rounded-md absolute top-20 left-8 ">
        <div className="speech-bubble w-80 bg-white border-2 border-gray-500 guideShadow rounded-md absolute top-28 md:top-20 left-8 ">
          <button onClick={subPop} className="text-2xl font-bold absolute text-white mainColor h-6 w-6 rounded" style={{ left: '89%', top:'5%'}}>X</button>
          <p className="font-bold text-xl p-4">You do not have an active subsription for this guide, activate your subsription to see your guide and share it with guests.</p>
        </div>
      )}
      {inShare === true && ( 
        <div className="absolute top-0 w-screen h-screen">
          <div className="absolute w-screen h-screen bg-gray-400 opacity-50" ></div>
          <div className="absolute w-screen h-screen" >
            <div className="m-auto mt-60 md:mt-96 bg-white w-11/12 md:w-1/2 rounded-lg pb-4">
              <header className="flex justify-between bg-gray-100 border-b-2 rounded-t-lg px-5 py-2 font-bold text-2xl mb-4">
                <h3>Share Guide Via...</h3>
                <button onClick={() => setInShare(false)}>X</button>
              </header>
              <div className="w-3/4 m-auto py-3">
              <a href={createEmail} className="mainColor text-white font-bold px-4 pb-1 pt-2 block w-full rounded-lg text-xl">Email</a>
              <div className="my-6">
                <button onClick={() => {setUrlAndDisplay(state?.guide)}} className="mainColor text-white font-bold px-4 pb-1 pt-2 w-full rounded-lg text-xl">QR Code</button>
              </div>
                <a href={createText} className="mainColor text-white font-bold px-4 pb-1 pt-2 block w-full rounded-lg text-xl">Text</a> 
              </div>
            </div>
          </div> 
        </div>
      )} 
    </div>
  )
};

export default EditGuide;