import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_USER_PASSWORD } from '../utils/mutations';
import { QUERY_CLIENT_USER } from '../utils/queries';
import logo from '../assets/icons/light.png';

const ChangePassword = () => {
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [updateUserPassword] = useMutation(UPDATE_USER_PASSWORD);
  const { code } = useParams();
  const { userId } = useParams();
  // eslint-disable-next-line
  const {loading, data} = useQuery(QUERY_CLIENT_USER,{
    variables: {
      userId: userId
    }
  });

  const [newPassword, setNewPassword] = useState({ password: "" }); 

  const handleLoginChange = (event) => {
    const { name, value } = event.target;
    setNewPassword({
      ...newPassword,
      [name]: value,
    });
  };

  const handleChangePassword = async() => {
    const updatedUser = await updateUserPassword({
      variables: {
        userId: userId,
        changePasswordId: code,
        password: newPassword.password
      }
    })
    if(updatedUser.data.updateUserPassword != null) {
      setPasswordChanged(true)
    }
  }

  if(data){
    if(data.clientUser.changePasswordId !== code) {
      return(
        <div>We are sorry but you do not have access to this page</div>
      )
    } 
  }

  return (
    <>
    <div className='w-screen h-screen p-20 bg-gray-100 font'>
    {passwordChanged === false ? (
      <div className='md:w-[600px] w-[300px] m-auto rounded-lg text-black text-xl p-5 pt-0 shadow-lg bg-white'>
        <img src={logo} className='m-auto w-[140px] mb-[-30px] '/>
        <h1 className='font-semibold'>Set new password</h1>
        <h3 className='text-sm text-gray-400'>Must be atleast 7 characters</h3>
        <p className='w-full text-left mt-4 text-sm mb-1'>New password</p>
        <input onChange={handleLoginChange} type="password" name="password" className='w-full px-3 p-1 rounded-lg inputText border-[1px]' placeholder='password' />
        <div className='w-full mt-6'>
          <button onClick={handleChangePassword} className='mainColor text-white p-2 pt-3 px-4 rounded-md'>Change Password</button>
        </div>
        <div className='w-full mt-2'>
        <Link className="text-sm" to='/login'>
        Cancel
            </Link>
        </div>
      </div> ) : (
        <div>
          <div className='md:w-[600px] w-[300px] m-auto rounded-lg text-black text-xl p-5 pt-0 shadow-lg bg-white'>
            <h1 className='my-4'>Password Succesfully Changed!</h1>
            <div className='my-8'>
              <a href='/login' className='mainColor text-white p-2 pt-3 px-4 rounded-md'>Go To Login Page</a>
            </div>
          </div>
        </div>)}
    </div>
    </>
  )
};

export default ChangePassword;