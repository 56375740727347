export const formatDate = (date) => {
  if (!date) {
    return date;
  }

  return new Date(+date).toLocaleDateString();
};

export const formatUnixDate = (date) => {
  if(!date){
    return date;
  }

  return new Date(date * 1000).toLocaleDateString();
}