import React, { useRef, useState } from 'react';
import { CANCEL_SUBSCRIPTION } from '../../utils/mutations';
import { useMutation } from '@apollo/client';

const DeleteSub = () => {

  let [cancelSubscription] = useMutation(CANCEL_SUBSCRIPTION);

  const [active, setActive] = useState(false);

  let userIdInput = useRef();

  const confirmDelete = async() => {
    console.log(userIdInput.current.value)

    let updatedUser = await cancelSubscription({
      variables: { userId: userIdInput.current.value}
    })

    console.log(updatedUser);
    setActive(false);
  }

  return (
    <div className='py-8'>
      <h1 className='homeText text-4xl font-bold'>Delete User's Subscription Here</h1>

      <p className='border-2 border-red-400 p-3 rounded-md w-fit m-auto font-bold text-xl my-8'>Before you remove a users subscription, cancel their subscription in the Stripe dashboard! (so you don't accidentally delete this subscription and forget to actually delete their monthly payments</p>

      <p>User's ID</p>
      <input ref={userIdInput} className=' px-2 border-2 rounded-md block m-auto mb-4'/>
      <button onClick={() => setActive(true)} className='mainColor text-white font-bold px-5 pb-1 pt-2 rounded-md'>Delete user's subscription</button>
      {active === true && (
        <div className='w-screen h-screen'>
          <div className='absolute top-0 w-screen h-screen bg-gray-400 opacity-40'></div>
          <div className='absolute top-40 left-[30%] bg-white w-fit h-fit pb-12 rounded-md border-2'>
            <h2 className='py-6 w-11/12 text-3xl font-bold m-auto'>Are you sure that you want to delete this user's subscription?</h2>

            <div className='flex w-fit m-auto'>
              <button onClick={() => setActive(false)} className='mainColor px-5 w-48 pb-1 pt-2 rounded-md text-white font-bold'>Cancel</button>
              <button onClick={confirmDelete} className='bg-red-600 px-5 w-48 pb-1 pt-2 rounded-md text-white font-bold ml-20'>Delete Subscription</button>
            </div>
            
          </div>
        </div>
      )}
    </div>
  )
}
export default DeleteSub;