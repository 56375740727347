import React, {useState, useEffect} from 'react';

import { ADMIN_FIND_USER } from '../../utils/queries'
import { useQuery } from "@apollo/client";


const FindUserAns = (props) => {
  const [user, setUser] = useState('')

  const email = props.email;
  const { loading, data} = useQuery(ADMIN_FIND_USER, {
    variables: {
      email: email
    }
  });


  useEffect(() => {
    console.log(data)
    setUser(data?.adminFinduser)
  }, data)

  return (
  <div className='border-2 w-fit m-auto p-5 px-12 rounded-lg mt-6'>
    <h1 className='text-left border-b-2  w-full flex justify-between'>
      <span className='font-bold mr-6'>User Email: 
      </span>
      {user?.email}
    </h1>
    <p className='text-left border-b-2  w-full flex justify-between'>
      <span className='font-bold mr-6'>User ID: 
      </span>
      {user?._id}
    </p>
    <p className='text-left border-b-2  w-full flex justify-between'>
      <span className='font-bold mr-6'>Stripe Customer ID: 
      </span>
      {user?.stripeCustId}
    </p>
    <p className='text-left border-b-2  w-full flex justify-between'>
      <span className='font-bold mr-6'>Stripe Subscription ID: 
      </span>
      {user?.subId}
      </p>
    <p className='text-left border-b-2  w-full flex justify-between'>
      <span className='font-bold mr-6'>Subscription payment Interval: 
      </span>
      {user?.subPayInterval}
      </p>
    <p className='text-left border-b-2  w-full flex justify-between'>
    <span className='font-bold mr-6'>Number Of Guides Allowed with subscription:  
    </span>
    {user?.subscriptionName}
    </p>
    <p className='text-left border-b-2  w-full flex justify-between'>
      <span className='font-bold mr-6'>Subscription Start Date: 
      </span>
      {user?.subStartDate}
      </p>
    <p className='text-left border-b-2  w-full flex justify-between'>
      <span className='font-bold mr-6'>Subscription End/NextPayment Date:
      </span>
        {user?.subscriptionNextPaymentDate}
      </p>
    <p className='text-left border-b-2  w-full flex justify-between'>
      <span className='font-bold mr-6'>Subscription Price: 
      </span>
      {user?.subscriptionPrice}
      </p>
    <p className='text-left w-full flex justify-between'>
      <span className='font-bold mr-auto'>Subscription Status: 
      </span>
      <span className='ml-auto'>
        {user?.subscriptionStatus}
      </span>
      </p>
  </div>
  );
}

export default FindUserAns;