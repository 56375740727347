import React, { useState } from "react";
import { useStoreContext } from '../../../utils/GlobalState';
import { useMutation } from "@apollo/client";
import { CANCEL_SUBSCRIPTION } from "../../../utils/mutations";  

const ManageSub = (props) => {
  const setManageSub = props.setManageSub;
  const [state] = useStoreContext();
  const user = state?.user;
  const [cancelSubscription] = useMutation(CANCEL_SUBSCRIPTION);
  // const handleCancelSubscription = async() => {
  //   console.log(user)
  //   const updatedUser = await cancelSubscription();
  //   console.log(updatedUser);
  //   if(updatedUser){
  //     window.location.reload();
  //   }
  // }

  return(
    <div className="absolute top-0 left-0 w-screen h-screen">
      <div className="absolute top-0 w-screen h-screen bg-gray-400 opacity-40"></div>
      <div className="absolute top-0 w-screen h-screen">
        <div className="sticky top-80 bg-white border-2 shadow w-1/3 m-auto rounded-md homeText">
          <div className="flex justify-between bg-gray-100 border-b-2 p-3 mb-2">

            <h2 className="text-2xl font-bold">Contact Support</h2>
            <button onClick={() => setManageSub(false)} className="homeText font-bold">X</button>
          </div>
          {/* <div className="text-left px-6">
            <h3>Subject</h3>
          <input className="border-2 rounded-md w-full mb-4"/>

          <h3>Your Message</h3>
          <textarea className="border-2 rounded-md w-full mb-4 h-40"/>
          </div>
          
          <button className="w-4/5 bg-gray-300 rounded-md p-2 mb-4">Send</button> */}
          <p className="m-auto w-11/12 text-xl py-6">Send us an email at <span className=" text-2xl font-bold underline">contact@vacanzeguides.com</span> with your questions or if you need support.</p>
        </div>
      </div>
    </div>
  )
}

export default ManageSub;