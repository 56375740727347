import React, { useRef, useState } from 'react';
import { DELETE_USER } from '../../utils/mutations';
import { useMutation } from '@apollo/client';

const DeleteUser = () => {
  const [active, setActive] = useState(false);
  const [deleteUser] = useMutation(DELETE_USER)

  const userIdInput = useRef();

  const confirmDelete = async() => {
    setActive(false)
    let deletedUser = await deleteUser({
      variables: { userId: userIdInput.current.value }
    })
    console.log(deleteUser)
  }

  return (
    <div className='py-3'>
      <label> Enter User's ID that you want to delete</label>
      <div>
        <input ref={userIdInput} className='border-2 rounded-md' />
      </div>
      <button onClick={() => setActive(true)} className='mainColor text-white font-bold px-5 pb-1 pt-2 rounded-md'>Delete User</button>
      {active === true && (
        <div className='w-screen h-screen'>
          <div className='absolute top-0 w-screen h-screen bg-gray-400 opacity-40'></div>
          <div className='absolute top-40 left-[30%] bg-white w-fit h-fit pb-12 rounded-md border-2'>
            <h2 className='py-6 w-11/12 text-3xl font-bold m-auto'>Are you sure that you want to delete this user?</h2>

            <div className='flex w-fit m-auto'>
              <button onClick={() => setActive(false)} className='mainColor px-5 w-48 pb-1 pt-2 rounded-md text-white font-bold'>Cancel</button>
              <button onClick={confirmDelete} className='bg-red-600 px-5 w-48 pb-1 pt-2 rounded-md text-white font-bold ml-20'>Delete Subscription</button>
            </div>
            
          </div>
        </div>
      )}
    </div>
  )
}

export default DeleteUser;