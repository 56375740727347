import React from "react";
import { Link } from "react-router-dom";
import study from '../../../assets/study.jpg'
import { useStoreContext } from "../../../utils/GlobalState";
import joinedBg from '../../../assets/joinedBG.png'

const NoGuides = () => {
  const [state] = useStoreContext();
  // console.log(state?.user)
  const subStatus = state?.user?.subscriptionStatus

  return (
  <>
  { subStatus !== 'active' ? (
    <div className="exampleHeader bg-white relative z-10 flex flex-wrap w-full m-auto pb-20" >
    <div className="relative z-30 md:w-1/2 mr-auto md:mt-48 mt-6  ml-6 md:ml-40">
      <h2 className="text-6xl font-bold text-left">Start creating your guest experience!</h2>
      <p className="text-left my-2 text-2xl">Subscribe to start creating your guide and improve you and your guest's experience!  </p>
      <div className="my-8 w-max m-auto md:mr-auto">
      <Link to='/Dashboard/addSubsription' className=' text-white text-2xl font-bold mainColor px-8 pt-3 py-2 m-auto rounded shadow mt-3'>Click here to subscribe</Link>
      </div>
    </div>
    <img className="absolute top-96 md:top-0 w-full" alt='img' src={joinedBg}/> 
      {/* <div className=" w-1/2 h-full m-auto pb-12">
      <Link to="/dashboard/addSubsription">
        <img className="w-[500px] md:w-full" alt='img' src={canvaG}/>    
      </Link>
      </div>
      <div className=" w-full">
      <Link to="/dashboard/addSubsription" className=" mainColor rounded-md py-2 pt-3 px-8 h-min w-60 text-2xl text-white">Subscribe Here</Link>
      </div> */}
      {/* <div className="w-2/5 h-full my-auto pb-20 pr-40">
        <h2 className="text-4xl pb-10 ">Click here to subscribe and start making your guide!</h2>
        <Link to="/dashboard/addSubsription" className="mainColor rounded-md py-2 pt-3 px-8 h-min w-60 text-2xl text-white">Subscribe Here</Link>
      </div> */}
  </div>
  ) : (
    <div className="exampleHeader bg-white relative z-10 flex w-full m-auto flex" >
      <div className="relative z-30 md:w-1/2 mr-auto mt-8 md:mt-32 ml-3 md:ml-40">
        <h2 className="text-6xl font-bold text-left">Start Creating your guest experience with a guide!</h2>
        <p className="text-left text-2xl  my-2">Begin your journey towards a better guest experience!  </p>
        <div className="my-6 w-max mr-auto">
          <Link to="/dashboard/addGuide" className="mainColor font-bold text-2xl rounded-md p-2 pt-3 px-8 text-white">+ Add Guide</Link>
        </div>
      </div>
      {/* <div className=" w-screen h-full px-20"> */}
        <img className="top-60 md:top-0 absolute md:w-full" alt='img' src={joinedBg}/>    
      {/* </div>
      <Link to="/dashboard/addGuide" className="mainColor rounded-md py-1 pt-2 px-3 h-min w-40 text-white">+ Add Guide</Link> */}
    </div>
  )}
  </>
  )
}

export default NoGuides;