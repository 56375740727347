import React, { useState, useEffect } from "react";
import { useMutation } from '@apollo/client';
import { TRANSFER_GUIDE } from '../../../utils/mutations';

const TransferGuide = (props) => {
  let [activeTransfer, setActiveTransfer] = useState(false);
  let [formState, setFormState] = useState();

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
    console.log(formState)
  };


  const [transferGuide] = useMutation(TRANSFER_GUIDE);
  const handleFormSubmit = async (event) => {
    try {
      
      const guide = await transferGuide({
        variables: {
          guideId: formState.id,
          email: formState.email
        },
      });
      if(guide){
        // tell me that guide was successfully transfered, and give me a link to it
        console.log(guide)
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div>
      <button onClick={()=>setActiveTransfer(true)}>Transfer Guide to User</button>
      {activeTransfer == true && (
        <div className="pb-20">
<input type="text" placeholder="Guide ID" name='id' maxLength={150} onChange={handleFormChange} className="inputText font-bold border-2 border-gray-200 text-black mx-2 rounded shadow w-full md:w-4/5 text-center mt-5" />
<input type="text" placeholder="New User Email" name='email' maxLength={50} onChange={handleFormChange} className="inputText font-bold border-2 border-gray-200 text-black mx-2 rounded shadow w-full md:w-4/5 text-center my-5" />
<div className="w-full flex justify-center">
<button onClick={handleFormSubmit}>Submit</button>
</div>
        </div>
      )}
    </div>
  )
}

export default TransferGuide;