import React, { useState } from "react";
import axios from 'axios';

import { useStoreContext } from "../../../utils/GlobalState";
import { Link } from "react-router-dom";
import { useMutation } from '@apollo/client';
import { ADD_GUIDE, ADD_POI } from '../../../utils/mutations';
import { formatPhoneNumber } from "../../../utils/formatPhone";
import { BsQuestionCircle } from 'react-icons/bs'

import SimpleFileUpload from "react-simple-file-upload";



function AddGuide() {


  const [state] = useStoreContext();
  const subscriptionStatus = state?.user?.subscriptionStatus;

  const [formState, setFormState] = useState({});
  const [size, setSize] = useState(0);
  const [primaryImageState, setPrimaryImageState] = useState();
  const [logoImageState, setLogoImageState] = useState()

  async function handlePrimaryImageFile(url){
    console.log('The URL of the file is ' + url)
    setPrimaryImageState(url);
    setSize(size + 1)
  //   const file = event.target.files[0];

  // const formData = new FormData();
  // formData.append('image', file);

  // try {
  //   const response = await axios.post('https://api.imgur.com/3/image', formData, {
  //     headers: {
  //       Authorization: `Client-ID 4ce824095ac8d4b`,
  //     },
  //   });
  //   console.log(response);
  //   const imageUrl = response.data.data.link;
  //   console.log(imageUrl)
  //   // do something with the image url, like save it to your db
  //   setPrimaryImageState(imageUrl);
  //   setSize(size + 1)
  // } catch (error) {
  //   console.log(error);
  // }
    
  }
  // eslint-disable-next-line


  function handleLogoFile(url){
    console.log('The URL of the file is ' + url)
    setLogoImageState(url);
    setSize(size + 1)
  }

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
    if(name === 'phone') {
      handlePhoneInput(event);
    }
    if(logoImageState?.length && primaryImageState?.length){
      setSize(Object.keys(formState).length + 2)
    } else if(logoImageState?.length || primaryImageState?.length){
      let length = Object.keys(formState).length + 1;
      setSize(length)
    } else {
      setSize(Object.keys(formState).length)
    }

  };

  const [addGuide] = useMutation(ADD_GUIDE);
  const [addPoi] = useMutation(ADD_POI);
  const handleFormSubmit = async (event) => {
    try {
      
      const guide = await addGuide({
        variables: {
          name: formState.name,
          address: formState.address,
          photo: primaryImageState,
          logo: logoImageState,
          contactPhone: formState.phone,
        },
      });
      //make this work for apartment numbers, it currently breaks if an address has an apartment number
      // example:  100 w 300e #23, ogden,utah 
      let beforeRemoveApt = guide.data.addGuide.address.split(' ');
      const filteredAddress = beforeRemoveApt.filter(str => !str.startsWith("#"));
      let geocodeAddress = filteredAddress.join('%20').concat('&');
      // let geocodeAddress = guide.data.addGuide.address.split(' ').join('%20').concat('&');

        const newLocation = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${geocodeAddress}key=AIzaSyDDkRTAj5v0klTMIXQeDdG8HcT0RbdwrOc`)
        .then(Response => Response.json())
        .then((data) => {
          console.log(data);
          const newLatLong = data.results[0].geometry.location;
          return newLatLong;
        })
        .catch((err) => console.log(err));
        const guideId = guide.data.addGuide._id
      const addPoiResponse = await addPoi({
        variables: {
          guideId: guideId,
          name: formState.name,
          address: guide.data.addGuide.address,
          lat: newLocation.lat,
          lng: newLocation.lng
        }
      });
      console.log(addPoiResponse);
      if(guide) {
        window.location.replace("/dashboard");
      }
      
    } catch (e) {
      console.log(e);
    }
  }

  //format phone nubmer

  const [inputValue, setInputValue] = useState('');
  const handlePhoneInput = e => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setInputValue(formattedPhone);
  }


  return(
    <div  className="bg-white w-11/12 md:w-2/3  max-w-[800px] m-auto rounded-md mt-4 mb-4 md:mt-12 guideShadow guide">
      <div className="flex justify-between mx-3 mt-3 py-3">
        <p className="p-2 px-5"></p>
        <p className="border-b-2 px-3 font-semibold border-cyan-700 pt-4">Start Creating Your Guide Here, <br/>you will be able to add more details and change things if needed later.</p>
        <Link to="/dashboard" className="h-12 md:h-full mainColor md:py-1 pt-3 md:pt-2 px-3 rounded text-white font-bold">X</Link>
      </div>
      
      <div className=" p-3 text-black flex flex-col">
        <label  className={(subscriptionStatus !== 'active') ? 'pointer-events-none m-2':"m-2"}>
          <div>
            Enter Your Guide's Name
            <div className="my-auto tooltip">
              <div className="top">
                <h3 className="font-bold">Guide Name</h3>
                <p className="text-md">This is the name that will show up when you share your guide with people and the name of the app if you save the guide to your smartphone.</p>
                <i></i>
              </div>
              <BsQuestionCircle className=" text-sm mr-2 text-gray-500 relative ml-3" style={{ top: '2px'}}/>
            </div>
          </div>
          <input type="text" placeholder="Beach House" name='name' maxLength={50} onChange={handleFormChange} className="inputText font-bold border-2 border-gray-200 text-black mx-2 rounded shadow w-full md:w-4/5 text-center" />
        </label>
        <label className={(subscriptionStatus !== 'active') ? 'pointer-events-none m-2':"m-2"}>
          <div>
            Enter Your Rental Property's Address:
            <div className="my-auto tooltip">
              <div className="top">
                <h3 className="font-bold">Guide Name</h3>
                <p className="text-md">This is the name that will show up when you share your guide with people and the name of the app if you save the guide to your smartphone.</p>
                <i></i>
              </div>
              <BsQuestionCircle className=" text-sm mr-2 text-gray-500 relative ml-3" style={{ top: '2px'}}/>
            </div>
          </div>
          <input type="text" placeholder="123 S Main Street, City, State 12345" name='address' maxLength={100} onChange={handleFormChange} className="inputText font-bold border-2 border-gray-200 text-black mx-2 rounded shadow w-full md:w-4/5 text-center" />
        </label>
        <label className={(subscriptionStatus !== 'active') ? 'pointer-events-none m-2':"m-2"}>
          <div>
            Enter the phone number you want guests to see:
            <div className="my-auto tooltip">
              <div className="top">
                <h3 className="font-bold">Phone Number</h3>
                <p className="text-md">This is the phone number that guests will be able to see on your guide and would use if they need to contact you.</p>
                <i></i>
              </div>
              <BsQuestionCircle className=" text-sm mr-2 text-gray-500 relative ml-3" style={{ top: '2px'}}/>
            </div>
          </div>
          <input type="text" name='phone' placeholder="(800) 248-6789" maxLength={15} onChange={handleFormChange} className="inputText font-bold border-2 border-gray-200 text-black mx-2 rounded shadow w-full md:w-4/5 text-center" 
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          value={inputValue}
          />
        </label>
        <div className="w-full flex md:flex-nowrap flex-wrap justify-center mt-3">
          <label className="m-2 w-full md:w-1/2">
          
            <div>
            
              Upload a Primary Photo:
              <div className="my-auto tooltip">
              <div className="top">
                <h3 className="font-bold">Primary Photo</h3>
                <p className="text-md">This will be the background picture for your guide, most users select a picture of their property or business.</p>
                <i></i>
              </div>
              <BsQuestionCircle className=" text-sm mr-2 text-gray-500 relative ml-3" style={{ top: '2px'}}/>
            </div>
            </div>
          <div className={(subscriptionStatus !== 'active') ? 'pointer-events-none m-auto w-full md:w-fit' : "m-auto w-fit"}>
            <SimpleFileUpload
              apiKey="b0c9e9b3278499b0b02bced650795a13"
              preview="true"
              onSuccess={handlePrimaryImageFile}
            />
            </div>
          </label>
          {/* <label className="m-2 w-full md:w-2/5">
            <div>
              Upload a Logo or use default logo:
              <div className="my-auto tooltip">
              <div className="top">
                <h3 className="font-bold">Logo</h3>
                <p className="text-md">The logo will show up on the home page of your guide and be the icon on smartphones if your guide is saved to the smartphone homescreen.</p>
                <i></i>
              </div>
              <BsQuestionCircle className=" text-sm mr-2 text-gray-500 relative ml-3" style={{ top: '2px'}}/>
            </div>
            </div>
            <div className={(subscriptionStatus !== 'active') ? 'pointer-events-none m-auto w-2/5' : "m-auto w-2/5"}>
              <SimpleFileUpload
                apiKey="b0c9e9b3278499b0b02bced650795a13"
                preview="true"
                onSuccess={handleLogoFile}
              />

            </div>

          </label>           */}
        </div>
        <button onClick={handleFormSubmit} className={size < 4 ? 'text-white font-bold mainColor px-10 py-1 pt-2 m-auto rounded shadow mt-3 opacity-70' : ' text-white font-bold mainColor px-10 py-1 pt-2 m-auto rounded shadow mt-3'}>Save Guide</button>

      </div>
      <div className="p-5"></div>
      {subscriptionStatus !== 'active' && (
        <div className="guideShadow bg-white w-11/12 left-4 md:left-1/4 md:w-1/2 absolute top-60 rounded-lg" style={{ border: '2px solid #068d9d'}}>
          <h2 className="mt-12 font-bold text-3xl homeText">To create a guide you must have an active subsription.</h2>
          <div className="mb-12 mt-6">
          <Link to='/Dashboard/addSubsription' className=' text-white font-bold mainColor px-4 pt-3 py-2 m-auto rounded shadow mt-3'>Click here to subscribe</Link>
          </div>
        </div>
      )}
      {subscriptionStatus === 'active' && state?.user?.guides?.length > state?.user?.subscriptionName && (
        <div className="guideShadow bg-white w-11/12 left-4 md:left-1/4 md:w-1/2 absolute top-60 rounded-lg" style={{ border: '2px solid #068d9d'}}>
          <h2 className="mt-12 font-bold text-3xl homeText">You already have as many guides as your subscription allows, to add more, update your subscription.</h2>
          <div className="mb-12 mt-6">
          <Link to='/Dashboard/account' className=' text-white font-bold mainColor px-4 pt-3 py-2 m-auto rounded shadow mt-3'>Go to account</Link>
          </div>
        </div>
      )} 
    </div>
  )
}

export default AddGuide;