import React, { useState } from "react";
import ActiveSubPage from "./activeSubscription";
import Notifications from "./notification";
import Settings from "./settings";
import Logo from '../../../assets/icons/light.png'

import { useStoreContext } from '../../../utils/GlobalState';

function Account() {
  const [state, dispatch] = useStoreContext();

  const [accountNav, setAccountNav] = useState('subscription');

  const handleAccountNav = (section) => {
    switch (section) {
      // case 'notifications':
      //   setAccountNav('notifications');
      //   break;
      case 'settings':
        setAccountNav('settings');
        break;  
      case 'subscription':
        setAccountNav('subscription');
        break;
      default:
        break;
    }
  }

  const displayAccountSection = () => {
    switch (accountNav) {
      // case 'notifications':
      //   return <Notifications />;
      case 'settings':
        return <Settings />;
      case 'subscription':
        return <ActiveSubPage />
      default:
        break;
    }
  }

return (
  <div id="profile-top" className=" bg-white homeText font" >
    <div className="flex flex-wrap w-5/6 m-auto">
          <img className="w-3/5 m-auto md:m-0 mb-4 md:w-1/6" src={Logo} />
    <div className="flex flex-wrap w-full md:w-3/4 justify-around m-auto pl-4">
        <h1 className="flex text-3xl font-black border-b-2 h-min pb-2 px-3 ml-0 md:ml-20">{state?.user?.username}'s Account</h1>
        <div className="w-full mt-3 md:mt-0 md:w-3/5 flex">
          <div className="w-full md:w-2/5 md:w-1/2 m-auto">
            <p className="text-gray-500 font-bold m-auto w-max">Guest contact email</p>
            <p className="font-bold">{state?.user?.email}</p>
          </div>
          <div className="w-full md:w-2/5">
            <p className="text-gray-500 font-bold m-auto w-max">Guides</p>
            <p className="font-bold">{state?.user?.guides?.length}</p>
          </div>
        </div>
        
    </div>
    </div>

    <div  className=" bg-gray-50 mb-5">
    <nav id="profile-nav" className="mainColor text-white md:bg-gray-50 w-full md:w-4/5 m-auto flex flex-wrap  mt-4 pt-5 font-semibold">
    <button 
      onClick={() => handleAccountNav('subscription')}
      className={accountNav === 'subscription' ? 'md:text-black navUnderline2  md:px-10 pb-5 hover-underline-animation' : 'md:text-black border-b-4 border-gray-50 md:px-10 pb-5 hover-underline-animation'}>
        Subscription
      </button>
      {/* <button 
      onClick={() => handleAccountNav('notifications')}
      className={accountNav === 'notifications' ? 'md:text-black navUnderline2  md:px-10 pb-5 hover-underline-animation' : 'md:text-black border-b-4 border-gray-50 md:px-10 pb-5 hover-underline-animation'}>
        Notifications
      </button> */}

      <button onClick={() => handleAccountNav('settings')}
      className={accountNav === 'settings' ? 'md:text-black navUnderline2  md:px-10 pb-5 hover-underline-animation' : 'md:text-black border-b-4 border-gray-50 md:px-10 pb-5 hover-underline-animation'}>
        Settings
      </button>
    </nav>
    </div>
    {displayAccountSection()}
  </div>
)
};

export default Account;