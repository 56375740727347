import React from "react";
import howItWorks1 from '../../../assets/howItWorks1.png' 
import tutorial1 from '../../../assets/tutorial1redo.png'
import tutorial2 from '../../../assets/tutorial2.png'
import tutorial3 from '../../../assets/tutorial3.png'
import tutorial4 from '../../../assets/tutorial4.png'

function Help() {

return (
  <>
  {/* <div className="max-w-[1000px] flex m-auto">
    <article className="w-1/2">Need help making guides?</article>
    <article className="w-1/2">Need help with account?</article>
  </div> */}
  <section className="guide my-10">
    <h1 className="text-5xl font-bold">How to Create and <br />Edit Guides on Vacanze</h1>
    <article className="flex m-auto w-11/12 max-w-[1100px] mt-8">
      <div className="w-1/2 flex flex-wrap align-center">
        <h2 className="font-bold text-4xl flex  mb-0 m-auto text-left mb-5 w-full">Creating A Guide</h2>
        <p className="font-medium text-lg flex mt-0 m-auto text-left pr-5">You can start making a guide after you have logged in and subscribed to Vacanze.
        <br/>
        <br/>
        This is where you will add the basic information about your property. What you want your Guide/App to be called, The address of your property, a contact number for guests to call, and a photo that will be used as the background picture of your app's homescreen.
        </p>
      </div>
      <div className="w-1/2 flex justify-center">
        <img src={tutorial1} className="flex m-auto"/>
      </div>
    </article>
    <article className="flex flex-wrap m-auto w-11/12 max-w-[1100px] mt-12">
      <div className="w-full flex flex-wrap align-center mb-4">
        <h2 className="font-bold text-4xl flex  mb-0 m-auto text-left mb-5 w-full">Guide Dashboard</h2>
        <p className="font-medium text-lg flex mt-0 m-auto text-left pr-5">Once you have made a guide, it and any other guides you make will show up in the 'guides' tab of your dashboard.
        <br/>
        <br/>
        This is where a lot of the main interactions with your guide will happen. 
        <br/>
        You can click 'See Guide' to view what your guests will see when you send it to them.  
        <br/>
        Clicking 'Edit Guide' will take you to where you will make updates and additions to your guidebook'.
        <br/>

        </p>
      </div>
      <div className="w-full flex justify-center">
        <img src={tutorial2} xlassName="flex m-auto"/>
      </div>
    </article>
    <article className="flex m-auto w-11/12 max-w-[1100px] mt-12 pt-6 border-t-2 border-solid border-gray-300">
      <div className="w-1/2 flex flex-wrap align-center">
        <h2 className="font-bold text-4xl flex  mb-0 m-auto text-left mb-5 w-full">Editing Your Guide's Design</h2>
        <p className="font-medium text-lg flex mt-0 m-auto text-left pr-5">When you click 'Edit Guide', you will be taken to the 'Edit Guide Photos & Styles'. Here is where you can change the nav bar and icon colors on your guide.
        <br/>
        <br/>
        You can also change the photo you want associated with your guide, and the app icon that will appear when your guests download the guidebook to their smartphone. 
        </p>
      </div>
      <div className="w-1/2 flex justify-center">
        <img src={tutorial3} className="flex m-auto"/>
      </div>
    </article>
    <article className="flex m-auto w-11/12 max-w-[1100px] mt-12 pt-6 border-t-2 border-solid border-gray-300">
    <div className="w-1/2 flex justify-center">
        <img src={tutorial4} className="flex m-auto sm:p-10"/>
      </div>
      <div className="w-1/2 flex flex-wrap align-center">
        <h2 className="font-bold text-4xl flex  mb-0 m-auto text-left mb-5 w-full">Editing Your Guide's Design</h2>
        <p className="font-medium text-lg flex mt-0 m-auto text-left pr-5">When you click 'Edit Guide', you will be taken to the 'Edit Guide Photos & Styles'. Here is where you can change the nav bar and icon colors on your guide.
        <br/>
        <br/>
        You can also change the photo you want associated with your guide, and the app icon that will appear when your guests download the guidebook to their smartphone. 
        </p>
      </div>
    </article>
  </section>
  </>
)
};

export default Help;