import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import disableScroll from 'disable-scroll';
import { GrStar } from 'react-icons/gr'
import { time } from "echarts";
const Reviews = (reviewArray) => {
  // console.log(reviewArray)
  const array = reviewArray.reviewArray;
  // const array = 
  // [
  //   {
  //     img: 'https://test-simple-file-uploadfddt.resize-files-simplefileupload.com/Xz53IBCg4KBzM1ruoJp4FHTF350hhtr0ofVbvLFkbSw/plain/s3://static.files-simplefileupload.com/sk0vgotpyiclsgevx12uy0tmp9wl',
  //     name: 'Doris Parke',
  //     guide: 'Fish Creek Lodge',
  //     link: 'https://vacanze.herokuapp.com/user/63d9d428f366de6c77a3f86c/guide/63e5195afc37c32a6db18017',
  //     testimonial: 'We love Vacanze! It has made running our rental properties so much easier!'
  //   },
  //   {
  //     img: 'https://cdn-ek1u0pq3.resize-files-simplefileupload.com/eaYQIso8ZmBXg4J2RFo8L2XZCaljNkbrqVYgA4yvo3E/plain/s3://static.files-simplefileupload.com/g6l1nuvgqx0zeks6n1vcdjq1niwr',
  //     name: 'Parker Saxton',
  //     guide: 'Anini Beach Bungalow',
  //     link: 'https://vacanze.herokuapp.com/user/63d9d428f366de6c77a3f86c/guide/6429d1e99590aabaa72cadae',
  //     testimonial: "I've cut the time I spent communticating with guests in half, while still giving them all the information the could need or want."
  //   },
  //   {
  //     img: 'https://www.switchbacktravel.com/sites/default/files/images/articles/Climbing%20down%20Sprinter%20van%20rear%20ladder%20%28on%20beach%29.jpg',
  //     name: 'Tanner Shepard',
  //     guide: 'Desert Getaway Van',
  //     link: 'https://vacanze.herokuapp.com/user/63d1f9a910d91e3807940d29/guide/6436e8585a5a2091cbf2e33a',
  //     testimonial: 'My rentals have gotten much more return business since I started using Vacanze!'
  //   },
  // ]
  
  


  return(
    <div className="bg-white w-full py-12 font">
      <h1 className="text-3xl font-bold homeText mb-10 w-11/12 m-auto">Testimony from our statisfied customers</h1>
      <div id="container"  className="flex flex-wrap md:flex-nowrap justify-center reviewScroll w-11/12 m-auto">
        {array.map((item, index)=>{
          return(
            <section key={index} className=" rounded-lg guideShadow mx-5 relative z-20 mb-2 w-[400px]">
              <img className="rounded-t-lg guideShadow w-full h-76 object-cover max-h-[200px]" alt="Testimonial Guide Image" src={item.img}/>
              <div className="p-3">
                <div className="flex pb-2">
                  <GrStar />
                  <GrStar />
                  <GrStar />
                  <GrStar />
                  <GrStar />
                </div>
                <p className="text-left w-full whitespace-normal my-2">"{item.testimonial}"</p>
                <p className="text-sm text-right  block">- {item.name} of <span className="text-cyan-600 font-bold hover:border-b-2 border-cyan-600 cursor-pointer"><a href={item.link} target="_blank">{item.guide}</a></span></p>
              </div>
            </section>
          )
        })}
        
      </div>
      <div className="w-full flex justify-between mb-[-50px]">
        <Link to='/alg' className="bg-green-400 w-12 h-12 cursor-default opacity-0">hi</Link>
        <div className=""></div>
      </div>
    </div>
  )
}

export default Reviews;