import React, { useState, useEffect } from 'react'
import { ADMIN_ALL_USERS } from '../../utils/queries'
import { useQuery } from "@apollo/client";

const AllUsers = () => {

  const [users, setUsers] = useState([])

  const { loading, data } = useQuery(ADMIN_ALL_USERS, {
    variables: {
      test: 'testString'
    }
  });

  useEffect(() => {
    if(data?.adminAllUsers)
    setUsers(data.adminAllUsers)
  }, [loading, data])

  return (
    <div className='pb-8'>
      ALL USERS
      <div className='flex border-2 w-11/12 m-auto justify-between pt-3 font-bold text-left pl-2'>
        <p className='w-[15%]'>Email</p>
        <p className='w-[15%]'>Username</p>
        <p className='w-[15%]'>ID</p>
        <p className='w-[7%]'>Sub Status</p>
        <p className='w-[13%]'>Stripe ID</p>
        <p className='w-[7%]'>Payment Interval</p>
        <p className='w-[10%]'>Sub Start</p>
        <p className='w-[10%]'>Sub End</p>
        <p className='w-[5%]'>Sub Amount</p>
        <p className='w-[5%]'>Sub Price</p>
      </div>
      {users.length > 0 && (
        users.map((user) => {
          return(
            <div key={user._id} className='text-left flex border-2 w-11/12 m-auto justify-between py-1 pl-2'>
              <p className='w-[15%]'>{user.email}</p>
              <p className='w-[15%]'>{user.username}</p>
              <p className='w-[15%]'>{user._id}</p>
              <p className='w-[7%]'>{user.subscriptionStatus}</p>
              <p className='w-[13%]'>{user.stripeCustId}</p>
              <p className='w-[7%]'>{user.subPayInterval}</p>
              <p className='w-[10%]'>{user.subStartDate}</p>
              <p className='w-[10%]'>{user.subscriptionNextPaymentDate}</p>
              <p className='w-[5%]'>{user.subscriptionName}</p>
              <p className='w-[5%]'>{user.subscriptionPrice}</p>
            </div>
          )
        })
      )}
    </div>
  )
}
export default AllUsers;