import React, { useState, useEffect } from 'react'
import FindUserAns from './findUserAns';


const FindUser = () => {

  const [email, setEmail] = useState();
  const [active, setActive] = useState(false);
  
  const handleClick = () => {
    if(active === true) {
      setActive(false)
    } else {
      setActive(true)
    }
  }

  const handleChange = (e) => {
    console.log(e.target.value)
    setActive(false)
    setEmail(e.target.value)
  }

  return (
    <div className='pb-8'>
      <div>
        <h2>Find user</h2>
        <p>enter user's email</p>
        <input onChange={e => handleChange(e)} id='userEmailinput' className='border-2 rounded-md'/>
      </div>
      
      <button onClick={handleClick} className='mainColor text-white px-3 pb-1 pt-2 rounded-md mt-4'>Submit</button>

      <div>
        {active === true && <FindUserAns email={email}/>}
      </div>
    </div>
  )
}
export default FindUser;