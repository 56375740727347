import React from "react";
import { Link } from "react-router-dom";
import vacanzePhone1 from '../../../assets/vacanzePhone1.png';
import marathon from '../../../assets/marathon.png'
import Reviews from "../details/reviews";
import fishCreekEx from '../../../assets/fishCreekPrev.png'
import anini from '../../../assets/anini.png';
import { MdStars, MdOutlineMobileScreenShare, MdOutlineScreenShare,MdQrCode2 } from 'react-icons/md';



const HostsOwners = () => {

  return (
    <div className="max-w-screen overflow-x-auto overflow-y-hidden">
    <header className="guidePageHeader">
        <article className="mainColor md:pt-20">
          <div className="md:w-3/4 flex flex-wrap justify-around m-auto">
            <div className="flex flex-wrap text-white md:w-1/2 ">
              <div className="w-full opacity-0 md:opacity-100"><p className="w-min text-left ml-16 pr-3 border-b-2 border-white h-min">Hosts</p></div>
              <h1 className="md:w-4/5 text-3xl md:text-4xl md:text-left font-bold pl-4 md:pl-0" >Streamline communication with your guests and customers to improve their experience with you(and your's with them!)</h1>
              <p className="md:w-4/5 text-2xl md:text-left">Make your short term rentals run smoothly with Vacanze.</p>
              <div className="m-auto my-6 md:m-0">
                <Link to='/login' className="bg-white text-xl font-black homeText h-12 rounded-xl px-3 py-3 mr-auto my-3">Start Your Guidebook</Link>
              </div>
              
            </div>
            
            <img className="w-48 pb-8 md:pb-0" src={vacanzePhone1} alt="img" />
          </div>
        </article>
        <svg className="curveSvg opacity-0 md:opacity-100" viewBox="0 0 1440 320"><path fill="#1e4259" fill-opacity="1" d="M0,32L60,42.7C120,53,240,75,360,74.7C480,75,600,53,720,64C840,75,960,117,1080,144C1200,171,1320,181,1380,186.7L1440,192L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path></svg>
      </header>
      <section className=" py-5 md:pb-0">
        <h2 className="text-4xl font-bold homeText">What will your guests see?</h2>
        <p className="homeText  text-2xl w-11/12 md:w-1/2 m-auto">Here are a few examples of Vacanze guidebooks that short term rental hosts have made.  Click on them to see the full guidebook. (feel free to steal any good ideas you see!)</p>
        <div className="w-full md:w-4/5 m-auto flex flex-wrap justify-center mt-20">
            <div className="ml-auto w-full md:w-1/3 pb-4">
              <p className="font-semibold">Fish Creek Lodge</p>
              <div className="secondColor m-auto mb-4 h-1 w-20"></div>
                <a target='_blank' rel="noreferrer" href="https://vacanze.herokuapp.com/user/63d9d428f366de6c77a3f86c/guide/63e5195afc37c32a6db18017" className="">
                  <img className="w-3/5 m-auto p-2" src={fishCreekEx} alt='img'/>
                </a>
            </div>
            <div className="mr-auto w-full md:w-1/3 pb-4">
              <p className="font-semibold">Anini Beach Bungalow</p>
              <div className="secondColor m-auto mb-4 h-1 w-20"></div>
                <a target='_blank' rel="noreferrer" href="https://vacanze.herokuapp.com/user/63d9d428f366de6c77a3f86c/guide/6429d1e99590aabaa72cadae" className="">
                  <img className="w-3/5 m-auto" src={anini} alt='img'/>
                </a>
            </div>
        </div>

      </section>
      <div className="flex justify-center">
      

      <Reviews 
      reviewArray={[
        {
          img: 'https://cdn-ek1u0pq3.resize-files-simplefileupload.com/euKO_vMy0dnPHgHqT3hr3FuPM1ZXoL93EnFHH5Wov84/plain/s3://static.files-simplefileupload.com/3kqe13w08dyawvsqh78ia8gxjb8c',
          name: 'Doris Parke',
          guide: 'Manzanita Beach House',
          link: 'https://vacanze.herokuapp.com/user/63d1f9a910d91e3807940d29/guide/6439e447740240b031d08b93',
          testimonial: 'We love Vacanze! It has made running our rental properties so much easier!'
        },
        {
          img: 'https://cdn-ek1u0pq3.files-simplefileupload.com/static/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBMDNpQWc9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--737079625ff8d1e4cbf1e5ef25fee919124e7abe/van.png',
          name: 'Parker Saxton',
          guide: 'Sierra Camper Van',
          link: 'https://vacanze.herokuapp.com/user/63d1f9a910d91e3807940d29/guide/6436e8585a5a2091cbf2e33a',
          testimonial: "I've cut the time I spent communticating with guests in half, while still giving them all the information the could need or want."
        },
        {
          img: 'https://cdn-ek1u0pq3.resize-files-simplefileupload.com/C4jnbdxr-PtAwGdsgucvIAwW5Ww74hAT_vTh5iMV0YM/plain/s3://static.files-simplefileupload.com/rgoyvrgnlbhd93lgtobyd2rmkcvl',
          name: "Jen Malan",
          guide: 'Zion Yurts',
          link: 'https://vacanze.herokuapp.com/user/63d1f9a910d91e3807940d29/guide/6439e8b7740240b031d08bd3',
          testimonial: 'My guests love our guidebook and are always commenting on it, Vacanze really makes our property stand out!'
        },
      ]}
      />
      </div>
      <svg className="mb-[-10rem] relative bottom-8" viewBox="0 0 1440 320"><path fill="#1e4259" fill-opacity="1" d="M0,32L80,48C160,64,320,96,480,101.3C640,107,800,85,960,80C1120,75,1280,85,1360,90.7L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>
      <section className="mainColor text-white py-12 relative bottom-12 mt-12 md:mt-0 mb-[-2rem] md:mb-[-5rem] ">
        <h2 className="text-5xl font-bold mb-2 relative bottom-8">Improve Your Hosting Experience</h2>
        <div className="flex flex-wrap justify-around w-11/12 md:w-4/5 m-auto text-2xl">
          <div className="w-full md:w-2/5">
            <div className="w-full text-left">
              <p className="flex"><MdStars className="mr-2 text-2xl"/>Get more 5 star reviews</p>
              <p className="flex"><MdStars className="mr-2 text-4xl md:text-2xl"/>Get more rentals & return customers</p>
              <p className="flex"><MdStars className="mr-2 text-5xl md:text-2xl"/>Improve quality of Life for youself & your guests</p>
              <p className="flex"><MdStars className="mr-2 text-6xl md:text-3xl"/>Share info about your home & local recommendations all in one place</p>
            </div>
            <div className="w-full flex my-6 md:my-0">
              <Link to='/login' className="bg-white text-xl font-black homeText rounded-xl px-6 py-2 md:ml-0 md:mr-auto m-auto mt-5">Start Your Journey</Link>
            </div>
          </div>
          <p className="hidden md:flex w-11/12 md:w-2/5 text-left">Make mangaing your short term rental fun and easy!  Don't let complicated instruction manuals and unread emails sabotage your guest communications. Take back your time and energy with a adaptable, charming digital guidebook! The only problem you'll have is deciding who loves it more - you, or your guests?!</p>
        </div>
      </section>
      <svg className="curveSvg bg-[#1e4259] w-full" viewBox="0 0 1440 320"><path fill="#e7dfc6" fillOpacity="1" d="M0,96L120,90.7C240,85,480,75,720,69.3C960,64,1200,64,1320,64L1440,64L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
      <section className="secondColor relative z-20 pt-16 bottom-12 m-auto">
      <h2 className="text-5xl font-bold homeText m-auto mb-3 relative bottom-6">Get your guests excited to visit your rental!</h2>
        <div className="w-full grid grid-cols-1 md:grid-cols-3 m-auto homeText text-2xl font-bold pb-8 w-full md:w-4/5">
            <div className="flex flex-wrap">
              <div className="flex flex-wrap m-auto mb-2 md:mb-5">
                <p className="m-auto w-4/5 m-auto">Share your guidebook through text</p>
              </div>
              
              <div className="w-full flex mb-12">
                <MdOutlineMobileScreenShare className="text-[150px] m-auto"/>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="flex flex-wrap m-auto mb-2 md:mb-5">
                <p className="m-auto w-4/5 m-auto">Print QR codes to guide and leave them at your property</p>
              </div>
              
              <div className="w-full flex mb-12">
              <MdQrCode2 className="text-[150px] m-auto"/>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="flex flex-wrap m-auto mb-2 md:mb-5">
                <p className="m-auto w-4/5 m-auto">Share your guidebook via email</p>
              </div>
              
              <div className="w-full flex mb-12">
                <MdOutlineScreenShare className="text-[150px] m-auto"/>
              </div>
            </div>

        </div>
        
      </section>
      <svg className=" curveSvg relative secondColor bottom-20" viewBox="0 0 1440 320"><path fill="#ffffff" fill-opacity="1" d="M0,32L80,48C160,64,320,96,480,101.3C640,107,800,85,960,80C1120,75,1280,85,1360,90.7L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>
      <section className="bottomGuidePage w-full pb-8 relative z-20 relative bottom-8 homeText">
        <h2 className="text-4xl font-bold mb-8">Want more Info on how Vacanze works?</h2>
        <Link to='/how' className="mainColor text-xl font-black text-white h-12 rounded-xl mt-6 px-6 py-2 ml-0 mr-auto">Learn more about Vacanze here</Link>

      </section>
    </div>
  )
}

export default HostsOwners;