import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import Select from 'react-select';
import calcPrice from "../../utils/calcPrice";

import {AiOutlinePlusCircle, AiOutlineMinusCircle} from 'react-icons/ai'

const Pricing = () => {

  const [selectedTheme, setSelectedTheme] = useState({value: 'Short Term Rental'})

  const styles = {
    container: base => ({
      ...base,
      flex: 1,
    })
  };
  const typeOptions = [
    {value: 'Short Term Rental', label: (
      <p className="homeText font-bold">Short Term Rental</p>
    )},
    {value: 'Bed & Breakfast', label: (
      <p className="homeText font-bold">Bed & Breakfast</p>
    )},
    {value: 'RV/CamperVan', label: (
      <p className="homeText font-bold">RV/CamperVan</p>
    )},
    {value: 'Campsite', label: (
      <p className="homeText font-bold">Campsite</p>
    )},
    {value: 'Other', label: (
      <p className="homeText font-bold">Other</p>
    )},
  ];

  const [numberOfText, setNumberOfText] = useState('Number of Homes')
  useEffect(() => {
    switch(selectedTheme.value) {
      case 'Short Term Rental':
        setNumberOfText('Number of Homes')
        return;
      case 'Bed & Breakfast':
        setNumberOfText('Number of Rooms')
        return;
      case 'RV/CamperVan':
        setNumberOfText('Number of Vehicles')
        return;
      case 'Campground':
        setNumberOfText('Number of Campgrounds')
        return;
      case 'Other':
        setNumberOfText('Number of Guides you would like to make')
        return;
      default:
        break;
    }
  }, [selectedTheme]);

  const [price, setPrice] = useState(false)

  const [monthly, setMonthly] = useState('--')
  const [yearly, setYearly] = useState('--')
  const calcPrices = (e) => {
    setPrice(true);
    console.log(e.target.value)
    const numberOfGuides = e.target.value;
    if(numberOfGuides > 0) {
      setMonthly(calcPrice('monthly', numberOfGuides))
      setYearly(calcPrice('yearly', numberOfGuides))
    } else {
      setMonthly('--')
      setYearly('--')
    }
    
  }
  console.log((yearly/12).toFixed(2))
  // console.log(parseInt(yearly) / 12)
  console.log(price)
  return (
    <div id='pricing' className="relative z-30 pb-8 homeText text-xl md:pt-12">
      <h1 className="text-4xl md:text-5xl font-bold ">Vacanze Pricing</h1>
      <div className="h-1 w-40 secondColor m-auto mb-6"></div>
      <p className="mt-2 text-xl w-11/12 md:w-1/3 m-auto">The price of Vacanze varies based upon a few things, choose your options below to see the pricing for your needs! </p>
      <div className="m-auto w-11/12 md:w-max my-4 pt-4">
        <p className="font-bold pb-1 text-2xl">What kind of guidebook do you want to make?</p>
        <Select
          name="guides"
          className="text-black m-auto"
          styles={styles}
          defaultValue={{value: 'Short Term Rental', label: (
            <p className="homeText font-bold">Short Term Rental</p>
          )}}
          placeholder={selectedTheme}
          onChange={setSelectedTheme}
          options={typeOptions}
        />
      </div>
        <div className="mb-8 pb-4">
          <p className="font-bold">{numberOfText}</p>
          <div className="">
            <div className="w-fit m-auto flex border-2 rounded-md">
            {/* <AiOutlineMinusCircle className="border-r-2 "/> */}
            <input onChange={calcPrices} type='text' className="text-center pt-[3px] border-r-2 border-l-2"/>
            {/* <AiOutlineMinusCircle  className="text-2xl"/> */}
            </div>
          </div>
          
        </div>
        <section className="m-auto flex justify-center mt-4 text-2xl mb-20">
          <article className="pr-4 md:pr-16">
            <h3 className="font-bold">Monthly Payments</h3>
            <p className="text-6xl mt-3">${monthly}</p>
            <p>(${price ? ((monthly * 12).toFixed(2)) : (monthly)} per year)</p>
          </article>
          <article className="pl-4 md:pl-16">
            <h3 className="font-bold">Yearly Payments</h3>
            <p className="text-6xl mt-3">${yearly}</p>
            <p>(${price === true ? (yearly/12).toFixed(2) : (yearly)} per month)</p>
          </article>
        </section>
        <Link to='/login' className="relative md:bottom-4 mainColor text-white px-8 py-3 rounded-md font-bold m-auto mt-20">Start your journey!</Link>
    </div>
  )
}

export default Pricing;