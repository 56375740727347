import { React } from "react";
import { Link } from "react-router-dom";
import { MdStars, MdOutlineMobileScreenShare, MdOutlineScreenShare,MdQrCode2, MdLibraryAdd, MdOutlineLockOpen, MdSecurityUpdateGood, MdTimer, MdRateReview } from 'react-icons/md';
import DemoSlider from "./demoSlider";
import HowItWorks1 from '../../assets/howItWorks1.png'
import HowItWorks2 from '../../assets/howItWorks2.png'

const HowItWorks = () => {
  
  return (
    <div>
      <div className="mainHeader">
        <header className="mainColor mb-0 flex flex-wrap md:flex-nowrap md:pt-12">
          <div className="w-11/12 md:w-full my-auto mx-auto md:mx-0">
            <h1 className=" text-6xl text-white font-bold mb-5">How It Works</h1>
            <p className="text-white text-2xl">Watch the slideshow below to see how Vacanze works.</p>
          </div>
          
          {/* <iframe className="hidden md:flex" height='350' width='600' src="https://www.youtube.com/embed/ekYtbpRBeF8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <iframe className="flex md:hidden m-auto my-6" height='180' width='320' src="https://www.youtube.com/embed/ekYtbpRBeF8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
        </header>
      <svg viewBox="0 0 1440 320" className="mb-40 md:mb-0 makeGuideSlideShow curveSvg"><path fill="#1e4259" fill-opacity="1" d="M0,64L48,74.7C96,85,192,107,288,106.7C384,107,480,85,576,74.7C672,64,768,64,864,69.3C960,75,1056,85,1152,101.3C1248,117,1344,139,1392,149.3L1440,160L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>
      </div>
      <div className="flex m-auto w-11/12 max-w-[1100px] mt-[-190px]">
          <article className="w-1/2 flex flex-wrap align-center">
            <h2 className="font-bold text-4xl flex  mb-0 m-auto text-left mb-5 w-full">Create Your Guide</h2>
            <p className="font-medium text-xl flex mt-0 m-auto text-left">Creating your guide is the first step, once you have created a guide you can start adding more personalized details.</p>
          </article>
          <article className="w-1/2 flex justify-center">
            <img src={HowItWorks1} xlassName="flex m-auto"/>
          </article>
        </div>
        <div className="flex m-auto w-11/12 max-w-[1100px] mt-8">
          <article className="w-1/2 flex justify-center">
            <img src={HowItWorks2} xlassName="flex mr-auto"/>
          </article>
          <article className="w-1/2 flex flex-wrap align-center">
            <h2 className="font-bold text-4xl flex  mb-2 m-auto text-left mb-3 w-full">Customize Your Guide</h2>
            <div className="font-medium text-xl flex flex-wrap mt-0 m-auto text-left">After creating your guide, you can start to add info and and customize it,
            <ul className="list-disc mt-3">
            <li>Changing the colors to match your brand.</li>
            <li>Choosing the app icon guests will download.</li>
            <li>Adding Useful info about checking in/out, wifi network & password, etc.</li>
            <li>Making custom map markers for your favorite local places to visit.</li>
            </ul>
            </div>
          </article>
        </div>
        <div className="flex m-auto w-11/12 max-w-[1100px] mt-8">
          <article className="w-1/2 flex flex-wrap align-center">
            <h2 className="font-bold text-4xl flex  mb-0 m-auto text-left mb-5 w-full">Share Your guide with guests!</h2>
            <p className="font-medium text-xl flex mt-0 m-auto text-left">Start sharing your guide and stop worring about your guests having the info they need.</p>
          </article>
          <article className="w-1/2 flex justify-center">
            <img src={HowItWorks1} xlassName="flex m-auto"/>
          </article>
        </div>
      <section className="homeText md:pb-12 relative">
        {/* <h2 className="text-5xl font-bold mb-3">How to Start:</h2> */}
        {/* <DemoSlider /> */}
        
        <div className="w-full flex justify-around m-auto mb-12">
          <article className="w-max mt-6">
            {/* <h4 className="text-3xl">Make your guide</h4> */}
            <Link to='/login'>
            <button className="mainColor text-white text-2xl font-bold px-6 py-1 pt-2 mt-40 md:mt-12 rounded-md">Get Started Here</button>
            </Link>
          </article>
        </div>
      </section>
      <section className="secondColor relative z-20 pt-16 bottom-12 m-auto mb-[-5rem]">
      <h2 className="text-4xl md:text-5xl w-11/12 font-bold homeText m-auto mb-3 relative bottom-6">Get your guests excited to visit your rental!</h2>
        <div className="w-full grid grid-cols-1 md:grid-cols-3 m-auto homeText text-2xl font-bold pb-8 w-full md:w-4/5">
            <div className="flex flex-wrap">
              <div className="flex flex-wrap m-auto mb-2 md:mb-5">
                <p className="m-auto w-4/5 m-auto">Share your guidebook through text</p>
              </div>
              
              <div className="w-full flex mb-12">
                <MdOutlineMobileScreenShare className="text-[150px] m-auto"/>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="flex flex-wrap m-auto mb-2 md:mb-5">
                <p className="m-auto w-4/5 m-auto">Print QR codes to guide and leave them at your property</p>
              </div>
              
              <div className="w-full flex mb-12">
              <MdQrCode2 className="text-[150px] m-auto"/>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="flex flex-wrap m-auto mb-2 md:mb-5">
                <p className="m-auto w-4/5 m-auto">Share your guidebook via email</p>
              </div>
              
              <div className="w-full flex mb-12">
                <MdOutlineScreenShare className="text-[150px] m-auto"/>
              </div>
            </div>
            {/* <div className="flex flex-wrap ">
              <div className="flex flex-wrap m-auto mb-2 md:mb-5">
                <p className="m-auto w-4/5 m-auto">Print QR codes to guide and leave them at your property</p>
              </div>
              <div className="w-full">
                <img className="w-48 m-auto mb-2" src={vacanzePhone1} alt="img" />
              </div>
            </div> */}
        </div>
        
      </section>
      <svg className="curveSvg secondColor" viewBox="0 0 1440 320"><path fill="#ffffff" fill-opacity="1" d="M0,96L120,90.7C240,85,480,75,720,69.3C960,64,1200,64,1320,64L1440,64L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
      <div className="py-8 w-4/5 m-auto pb-12">
        <h2 className="text-3xl md:text-5xl mb-3 homeText font-bold pt-2 md:pt-0">Enjoy the benefits of your guide</h2>
        <div className="md:w-[1200px] text-left flex flex-wrap m-auto justify-center pt-20">

            <div className="w-[350px] shadow rounded-2xl homeText mt-3 px-3 mx-5 bg-[#f2f2f2] py-0 shadow mb-20 pb-3">
              <div className="mainColor m-auto w-fit rounded-2xl p-3 px-5 relative top-[-60px] mb-[-40px] ">
              <MdLibraryAdd className="text-6xl relative text-white mr-auto ml-auto mb-4" />
              </div>
              <h3 className="font-bold text-center w-full mb-1">Easily Create Content</h3>
              <p className="flex homeText text-center w-full mr-auto">Easily add new information, change existing content, or make updates as needed.</p>
            </div>
            <div className="w-[350px] shadow rounded-2xl homeText mt-3 px-3 mx-5 bg-[#f2f2f2] py-0 shadow mb-20 pb-3">
              <div className="mainColor m-auto w-fit rounded-2xl p-3 px-5 relative top-[-60px] mb-[-40px] ">
              <MdOutlineLockOpen className="text-6xl relative text-white mr-auto ml-auto mb-4" />
              </div>
              <h3 className="font-bold text-center w-full mb-1">Simple Use</h3>
              <p className="flex homeText text-center w-full mr-auto">Allows guests to access all the information they need from their smartphone or tablet, making it more convenient for them.</p>
            </div>
            <div className="w-[350px] shadow rounded-2xl homeText mt-3 px-3 mx-5 bg-[#f2f2f2] py-0 shadow mb-20 pb-3">
              <div className="mainColor m-auto w-fit rounded-2xl p-3 px-5 relative top-[-60px] mb-[-40px] ">
              <MdSecurityUpdateGood className="text-6xl relative text-white mr-auto ml-auto mb-4" />
              </div>
              <h3 className="font-bold text-center w-full mb-1">Simple Use</h3>
              <p className="flex homeText text-center w-full mr-auto">Increase guest satisfaction by including all the information guests need to improve their overall experience.</p>
            </div>
            <div className="w-[350px] shadow rounded-2xl homeText mt-3 px-3 mx-5 bg-[#f2f2f2] py-0 shadow  mb-20 pb-3">
              <div className="mainColor m-auto w-fit rounded-2xl p-3 px-5 relative top-[-60px] mb-[-40px] ">
              <MdTimer className="text-6xl relative text-white mr-auto ml-auto mb-4" />
              </div>
              <h3 className="font-bold text-center w-full mb-1">Save Time</h3>
              <p className="flex homeText text-center w-full mr-auto">Cut the time you spend communicating with guests in half by removing unnecessary points of contact before, during, and after their stay.</p>
            </div>
            <div className="w-[350px] shadow rounded-2xl homeText mt-3 px-3 mx-5 bg-[#f2f2f2] py-0 shadow  mb-20 pb-3">
              <div className="mainColor m-auto w-fit rounded-2xl p-3 px-5 relative top-[-60px] mb-[-40px] ">
              <MdRateReview className="text-6xl relative text-white mr-auto ml-auto mb-4" />
              </div>
              <h3 className="font-bold text-center w-full mb-1">Better Reviews</h3>
              <p className="flex homeText text-center w-full mr-auto">Improve your guest's experience with easy access to everything they need and get more 5 star reviews</p>
            </div>
            <div className="w-[350px] shadow rounded-2xl homeText mt-3 px-3 mx-5 bg-[#f2f2f2] py-0 shadow  mb-20 pb-3">
              <div className="mainColor m-auto w-fit rounded-2xl p-3 px-5 relative top-[-60px] mb-[-40px] ">
              <MdStars className="text-6xl relative text-white mr-auto ml-auto mb-4" />
              </div>
              <h3 className="font-bold text-center w-full mb-1">impress Guests</h3>
              <p className="flex homeText text-center w-full mr-auto">Make your rental look professional and polished by creating a welcome book that matches the style and feel of your property.</p>
            </div>

        </div>
      </div>
    </div>
  )
}

export default HowItWorks;