import React from "react";
import { Link } from "react-router-dom";
import vacanzePhone1 from '../../../assets/vacanzePhone1.png';
import favGuidePre from '../../../assets/favGuidePre.png'
import star from '../../../assets/star.png';
import { MdStars } from 'react-icons/md';

const Destinations = () => {

  return (
    <div>
    <header className="">
        <article className="mainColor md:pt-20">
          <div className="md:w-3/4 flex flex-wrap justify-around m-auto">
            <div className="flex flex-wrap text-white md:w-1/2 ">
              <div className="w-full opacity-0 md:opacity-100"><h1 className="w-min text-left ml-16 pr-3 border-b-2 border-white h-min">Hosts</h1></div>
              <p className="md:w-4/5 text-5xl text-left font-bold pl-4 md:pl-0" >Make your Destinations run smoothly with Vacanze.</p>
              <p className="md:w-4/5 text-2xl md:text-left">Make your short term rentals run smoothly with Vacanze.</p>
              <div className="m-auto my-6 md:m-0">
                <Link to='/' className="bg-white text-xl font-black homeText h-12 rounded-xl py-3 mr-auto my-3">Start Your Guidebook</Link>
              </div>
            </div>
            
            <img className="w-48 pb-4 md:pb-0" src={vacanzePhone1} alt="img" />
          </div>
        </article>
        <svg className="curveSvg opacity-0 md:opacity-100" viewBox="0 0 1440 320"><path fill="#1e4259" fill-opacity="1" d="M0,32L60,42.7C120,53,240,75,360,74.7C480,75,600,53,720,64C840,75,960,117,1080,144C1200,171,1320,181,1380,186.7L1440,192L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path></svg>
      </header>
      <section className=" py-5 md:pb-20">
        <h2 className="text-4xl font-bold homeText">What will your guests see?</h2>
        <p className="homeText  text-2xl w-11/12 md:w-1/2 m-auto">Here are a few examples of Vacanze guidebooks that short term rental hosts have made.  Click on them to see the full guidebook. (feel free to steal any good ideas you see!)</p>
        <div className="w-full md:w-4/5 m-auto flex flex-wrap justify-around mt-12">
          <div className='md:w-1/4 homeText pb-4 md:pb-0'>
            <h3 className="font-bold homeText text-xl">Hanalei House</h3>
            <div className="h-1 w-1/3 m-auto secondColor mt-2 mb-6"></div>
            <img className="w-11/12 md:w-full m-auto mb-2" src={favGuidePre} alt="img" />
          </div>
          <div className='md:w-1/4 homeText pb-4 md:pb-0'>
            <h3 className="font-bold homeText text-xl">Hanalei House</h3>
            <div className="h-1 w-1/3 m-auto secondColor mt-2 mb-6"></div>
            <img className="w-11/12 md:w-full m-auto mb-2" src={favGuidePre} alt="img" />
          </div>
        </div>

      </section>
      <section className=" pb-8 homeText">
        <div className="flex flex-wrap md:flex-nowrap">
          <div className=" mx-3 my-5">
            <img className="w-1/3 m-auto" alt="stars" src={star} />
            <h2 className="text-xl font-bold my-3">The Best App!</h2>
            <p>This app has made renting out my property so much better! lorem ipsum latin belt bvlah clamp stamp talking so good!</p>
          </div>
          <div className="mx-3 my-5">
            <img className="w-1/3 m-auto" alt="stars" src={star} />
            <h2 className="text-xl font-bold my-3">The Best App!</h2>
            <p>This app has made renting out my property so much better! lorem ipsum latin belt bvlah clamp stamp talking so good!</p>
          </div>
          <div className=" mx-3 my-5">
            <img className="w-1/3 m-auto" alt="stars" src={star} />
            <h2 className="text-xl font-bold my-3">The Best App!</h2>
            <p>This app has made renting out my property so much better! lorem ipsum latin belt bvlah clamp stamp talking so good!</p>
          </div>
        </div>
      </section>
      <svg className="curveSvg relative bottom-8" viewBox="0 0 1440 320"><path fill="#1e4259" fill-opacity="1" d="M0,32L80,48C160,64,320,96,480,101.3C640,107,800,85,960,80C1120,75,1280,85,1360,90.7L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>
      <section className="mainColor text-white py-12 relative bottom-12">
        <h2 className="text-5xl font-bold mb-2 relative bottom-8">Improve Your Hosting Experience</h2>
        <div className="flex flex-wrap justify-around w-4/5 m-auto text-2xl">
          <div className="w-full md:w-2/5">
            <div className="w-full text-left">
              <p className="flex"><MdStars className="mr-2 text-2xl"/>Get more 5 star reviews</p>
              <p className="flex"><MdStars className="mr-2 text-4xl md:text-2xl"/>Get more rentals & return customers</p>
              <p className="flex"><MdStars className="mr-2 text-5xl md:text-2xl"/>Improve quality of Life for youself & your guests</p>
              <p className="flex"><MdStars className="mr-2 text-6xl md:text-3xl"/>Share info about your home & local recommendations all in one place</p>
            </div>
            <div className="w-full flex my-6 md:my-0">
              <Link to='/login' className="bg-white text-xl font-black homeText rounded-xl px-6 py-2 ml-0 mr-auto mt-5">Start Your Journey</Link>
            </div>
          </div>
          <p className="w-11/12 md:w-2/5 text-left md:text-right">Make mangaing your short term rental fun and easy!  Don't let complicated instruction manuals and unread emails sabotage your guest communications. Take back your time and energy with a adaptable, charming digital guidebook! The only problem you'll have is deciding who loves it more - you, or your guests?!</p>
        </div>
      </section>
      <section className="secondColor relative z-20 pt-16 bottom-12 m-auto">
      <h2 className="text-5xl font-bold homeText m-auto mb-3 relative bottom-6">Get your guests excited to visit your rental!</h2>
        <div className="w-full grid grid-cols-1 md:grid-cols-3 m-auto homeText text-2xl">
            <div className="flex flex-wrap">
              <div className="flex flex-wrap m-auto mb-2 md:mb-5">
                <p className="m-auto w-full">Share your guidebook via email or text</p>
              </div>
              
              <div className="w-full">
                <img className="w-48 m-auto mb-2" src={vacanzePhone1} alt="img" />
              </div>
            </div>
            <div className="flex flex-wrap ">
              <div className="flex flex-wrap m-auto mb-2 md:mb-5">
                <p className="m-auto w-full">Print QR codes to guide and leave them at your property</p>
              </div>
              <div className="w-full">
                <img className="w-48 m-auto mb-2" src={vacanzePhone1} alt="img" />
              </div>
            </div>
            <div className="flex flex-wrap ">
              <div className="flex flex-wrap m-auto mb-2 md:mb-5">
                <p className="m-auto w-full">Print QR codes to guide and leave them at your property</p>
              </div>
              <div className="w-full">
                <img className="w-48 m-auto mb-2" src={vacanzePhone1} alt="img" />
              </div>
            </div>
        </div>
        
      </section>
      <svg className=" curveSvg relative secondColor bottom-20" viewBox="0 0 1440 320"><path fill="#ffffff" fill-opacity="1" d="M0,32L80,48C160,64,320,96,480,101.3C640,107,800,85,960,80C1120,75,1280,85,1360,90.7L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>
      <section className="w-full pb-8 relative z-20 relative bottom-8 homeText">
        <h2 className="text-4xl font-bold ">Want more Info on how Vacanze works?</h2>
        <p className="text-xl pt-2 w-11/12 md:w-1/2 m-auto">Here is a demo video showing step-by-step how to get started!</p>
        <p  className="text-xl pb-2 mb-3">Or you can click below to see more.</p>
        <Link to='/how' className="mainColor text-xl font-black text-white h-12 rounded-xl px-6 pt-2 pb-1 ml-0 mr-auto">Learn more about Vacanze</Link>
        <div className="m-auto mt-8">
        <div className="hidden md:block w-max h-full m-auto">
          <iframe height='350' width='600' src="https://www.youtube.com/embed/ekYtbpRBeF8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="block md:hidden w-max m-auto">
          <iframe height='200' width='350' src="https://www.youtube.com/embed/ekYtbpRBeF8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        </div>
      </section>
    </div>
  )
}

export default Destinations;