import React, { useState, useEffect, useRef } from "react";
import { useStoreContext } from "../../../utils/GlobalState";
import { UPDATE_SUBSCRIPTION } from "../../../utils/mutations";
import { useMutation  } from "@apollo/client";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { BsQuestionCircle } from 'react-icons/bs';
import calcPrice from './../../../utils/calcPrice';


const UpgradeSub = (props) => {
  const setPayment = props.setPayment
  const [state] = useStoreContext();
  let [price, setPrice] = useState(state?.user?.subscriptionPrice);
  let interval = state?.user?.subPayInterval;
  console.log(calcPrice('monthly', '2'))
  const [guideNumber, setGuideNumber] = useState(parseInt(state?.user?.subscriptionName));
  
  const [checked, setChecked] = useState(false);
  const [chooseInterval, setChooseInterval] = useState(interval)
  
  const [updateSubscription] = useMutation(UPDATE_SUBSCRIPTION)

  const checkBox = useRef();
  const subtract = useRef();

  useEffect(() => {
    if(interval === 'monthly'){
      setPrice(calcPrice('monthly', guideNumber.toString()))
    } else {
      setPrice(calcPrice('yearly', guideNumber.toString()))
    }
    if(guideNumber < 2) {
      setGuideNumber(1);
      subtract.current.classList.add('pointer-events-none')
      subtract.current.parentNode.classList.add('notAllowed')
    } else {
      subtract.current.classList.remove('pointer-events-none')
      subtract.current.classList.remove('notAllowed')
    }
  }, [guideNumber])
  
  const setUpgradeSub = props.setUpgradeSub;

  const radioClick = (checkedInterval) => {
    setChooseInterval(checkedInterval)
    let monthRadio = document.getElementById('monthRadio');
    let yearRadio = document.getElementById('yearRadio');

    if(checkedInterval === 'monthly'){
      yearRadio.checked = false;
      monthRadio.checked = true;
    }
    if(checkedInterval === 'yearly'){
      monthRadio.checked = false;
      yearRadio.checked = true;
    }
  }
  useEffect(() => {
  radioClick(chooseInterval);
  }, [])
  

  const changeCheck = () => {
    if(checkBox.current.checked){
      setChecked(true)
    } else (
      setChecked(false)
    )
    
  }
// console.log(state)
  const [updateSucess, setUpdateSucess] = useState(false)

  const handleCloseUpdate = () => {
    setUpdateSucess(false)
    setUpgradeSub(false)
  }
  console.log(updateSucess)

  const confirmUpgrade = async() => {
    console.log(chooseInterval)
    try{
      const updated = await updateSubscription({
        variables: {
          subId: state?.user?.subId,
          userId: state?.user?._id,
          interval: state?.user?.subPayInterval,
          guideNumber: guideNumber
        }
      })
      console.log(updated)
      if(updated){
        setPayment(updated.subscriptionPrice)
        setUpdateSucess(true)
        // window.location.reload();
      } 
    }catch(e) {
      console.log(e)
    }
  }
  
  return (
    <div className="w-screen h-screen absolute top-0 left-0">
      <div className="w-screen h-screen absolute top-0 left-0 bg-gray-400 opacity-60"></div>
      {/* <div className="m-auto"> */}
        <div className="border-2 bg-white relative top-0 m-auto top-80 w-fit rounded-md">
          {updateSucess === true ? (
            <div className="w-[300px]">
              <div className="flex justify-between bg-gray-100 border-b-2 rounded-t-lg px-4 py-2 text-2xl">
                <h2>Success!</h2>
                <button onClick={handleCloseUpdate}> X</button>
              </div>
              <p className="m-auto p-4">Your subscription was sucessfully updated!</p>
            </div>) : (
            <>
          <div className="flex justify-between bg-gray-50 border-b-2 border-gray-200 mb-3 p-3">
            <h1>Upgrade your plan</h1>
            <button onClick={() =>setUpgradeSub(false)}>X</button>
          </div>
          <div className=" p-3 px-6">
            <div className="flex justify-between">
              <h3>Number of guides
              <div className="my-auto tooltip">
                <div className="top">
                  <p className="text-md">Need to adjust the minimum or maximum number of guides on your subscription? Please contact us through the support tab for assitstance.</p>
                  <i></i>
                </div>
                <BsQuestionCircle className=" text-sm mr-2 text-gray-500 relative ml-3" style={{ top: '2px'}}/>
              </div>
              </h3>
              <div className="border-2 rounded-md flex w-1/2">
                <div className="relative pt-1 bg-gray-100">
                <button ref={subtract} onClick={() => setGuideNumber(guideNumber - 1)} className="px-1 relative top-1"><AiOutlineMinusCircle/></button>
                </div>
                <p className="border-r-2 border-l-2 w-full pt-1">{guideNumber}</p>
                <div className="relative pt-1 bg-gray-100">
                <button onClick={() => setGuideNumber(guideNumber + 1)} className="px-1 relative top-1"><AiOutlinePlusCircle/></button>
                </div>
              </div>
            </div>
            <section className="flex mt-4">
              <h2 className="mr-auto">Payment Interval: </h2>
              <article className="w-1/2 flex justify-center">
                <div className="mr-6">
                  <h4>Monthly</h4>
                  <input onClick={() => radioClick('monthly')} id="monthRadio" type='radio'/>
                </div>
                <div>
                  <h4>Yearly</h4>
                  <input onClick={() => radioClick('yearly')} id="yearRadio" type='radio'/>
                </div>
              </article>
            </section>
            <p className="w-full text-left font-bold text-xl">Total: ${price} / {chooseInterval}</p>
            <div className="flex justify-between pt-3"> 
              <input onChange={changeCheck} ref={checkBox} type='checkbox' className="w-12 h-12 rounded mr-3 cursor-pointer"/>
              <p className="text-sm text-left mt-2 w-2/3 mr-auto">I acknowledge this is a subscription service and authorise you to charge my card for all future subscription payments until the service is cancelled as described in the Terms and Conditions.</p>
            </div>
            <div className="flex justify-center ml-auto w-fit">
              <button onClick={() =>setUpgradeSub(false)} className="px-3 py-1 pt-2 rounded-md bg-gray-300">Cancel</button>
              {(checked && guideNumber != state?.user?.subscriptionName) ? (
                <button onClick={confirmUpgrade} className="px-3 py-1 pt-2 rounded-md mainColor text-white ml-5">Upgrade</button>
              ) : (
                <button className="px-3 py-1 pt-2 rounded-md mainColor text-white ml-5 pointer-events-none opacity-60">Upgrade</button>
              )}
              
            </div>
            
          </div>
          </>
          )}
        </div>
      {/* </div>  */}
    </div>
  )
}

export default UpgradeSub;