import React, { useState, useEffect, useRef } from "react";
import { formatDate } from "../../../utils/dateFormat";
import greenCheck from '../../../assets/greenCheck.png';
import StripePage from "../../stripe/Stripe";
import calcPrice from "../../../utils/calcPrice";
import logo from '../../../assets/icons/dark-png.png';

import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai'


import { useStoreContext } from '../../../utils/GlobalState';
import { SET_ORDER } from "../../../utils/actions";
import { Link } from "react-router-dom";


function  AddSubscription() {

  let pricing = {

  }

  const [formState, setFormState] = useState({ interval: 'monthly', numberOfGuides: 1 });
  const [numberOfGuides, setNumberOfGuides] = useState(1)
  const handleFormChange = (event) => {
    let { name, value } = event.target;
    
    if(value < 1) value = 1;
    console.log(value)
    setFormState({
      ...formState,
      [name]: value,
    })
    dispatch({
      type: SET_ORDER,
      order: formState,
    });
  };
  const confirmOrderForm = () => {
    dispatch({
      type: SET_ORDER,
      order: {
        interval: formState?.interval,
        numberOfGuides: numberOfGuides
      },
    });
  }

  const [state, dispatch] = useStoreContext();   
  const [clickedOption, setClickedOption] = useState('monthly');
  console.log(clickedOption)

  const monthly = useRef();
  const monthlyCheck = useRef();

  const yearly = useRef();
  const yearlyCheck = useRef();

  const handleSelect = async (interval) => {
    console.log(interval)
    setFormState({
      ...formState,
      interval: interval
    })
    monthly.current.classList.toggle('border-4')
    monthly.current.classList.toggle('border-cyan-900')
    monthly.current.classList.toggle('pointer-events-none');
    

    yearly.current.classList.toggle('border-4')
    yearly.current.classList.toggle('border-cyan-900')
    yearly.current.classList.toggle('pointer-events-none')
    setClickedOption(interval)
  };
  useEffect(() => {
    confirmOrderForm()
  }, [formState?.interval, formState, numberOfGuides])

  const yearPrice = 99.99;
  const monthPrice = 8.99;

  let paymentM = '--';
  let paymentY = '--';
  if (numberOfGuides > 0){
    paymentM = calcPrice('monthly', numberOfGuides.toString())
    paymentY = calcPrice('yearly', numberOfGuides.toString())
  }

  // const startDate = new Date();
  // const endDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

  // console.log(clickedOption)


  return (
    <div>      
      <div className="border-[1px] md:w-[600px] w-11/12 m-auto rounded-md mt-5 shadow">
        <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
        {/* <stripe-pricing-table pricing-table-id="prctbl_1MEjtxDrsHKeDJHuQvI88FWw"
        publishable-key="pk_test_51LB0sYDrsHKeDJHuCuUapD7BFPpMWn2P0TNLlambCQYuFTByFw00r4uu0jZYJDqzeF3ruBIehqCMfNeGTRcAR2Qz00UsMobyPb">
        </stripe-pricing-table> */}
        <div className="flex space-between">
          <img src={logo} className="w-[100px] mr-auto mt-[-20px]"/>
          <Link to='/dashboard' className="font-[900] text-gray-400 pr-3 pt-3">X</Link>
        </div> 
        <p className="font-bold pt-2 w-1/2 m-auto mb-0 border-b-2 border-white text-2xl mt-4 mb-[-5px]">Choose Your Subsription Here.</p>
        <p className="text-blue-600 font-semibold">(Discount codes can be applied at checkout)</p>
        <div className=" flex flex-wrap justify-around py-3">
          <div className="w-full flex flex-wrap mb-4">
            <label className="text-center w-full text-xl font-bold">How many Guides do you want to make?</label>
            <div className="flex border-[3px] rounded-md m-auto my-3">
              <div onClick={() => setNumberOfGuides(numberOfGuides - 1)} className="px-3 py-1 pt-2">
                <AiOutlineMinusCircle className="text-3xl"/>
              </div>
              <p type="number" min='1' name="numberOfGuides" placeholder="Enter # here" className="m-auto w-60 text-center border-l-2 border-r-2 pt-2 text-3xl">{numberOfGuides}</p>
              <div onClick={() => setNumberOfGuides(numberOfGuides + 1)} className="px-3 py-1 pt-2">
                <AiOutlinePlusCircle className="text-3xl"/>
              </div>
            </div>
            
          </div>
          <div className="w-full flex flex-wrap justify-center">
            <label className="text-center w-full text-xl font-bold mb-4">Do you want to pay monthly or yearly?</label>
              <button onClick={() => handleSelect('monthly')} ref={monthly} name='monthly' value="monthly" className={ numberOfGuides > 0 ? "border-2 rounded-md p-2 md:p-4 mb-3 md:mb-0 md:mr-6 border-4 border-cyan-900 pointer-events-none" : "pointer-events-none border-2 rounded-md p-2 md:p-4 mb-3 md:mb-0 md:mr-6"}>
                { (formState?.numberOfGuides > 0 & clickedOption === 'monthly') ? <img src={greenCheck} alt='checkmark'  className="w-12 h-12 relative bottom-10 left-28" style={{marginBottom: '-45px'}}/> : <></>}
                <h3 className="font-bold text-2xl">Monthly</h3>
                <p className="text-4xl py-4">${paymentM}</p>
                { numberOfGuides > 0 ?(<p>(${(paymentM * 12).toFixed(2)} Per Year)</p>) :  (<p>(${paymentM} Per Year)</p>) }
              </button>
              <button onClick={() => handleSelect('yearly')} ref={yearly}  name='yearly' value="yearly" className={ numberOfGuides > 0 ? "p-2 md:p-4 mb-3 md:mb-0 md:mr-6  border-2 rounded-md" : "p-2 md:p-4 mb-3 md:mb-0 md:mr-6  pointer-events-none border-2 rounded-md"}>
                {clickedOption === 'yearly' && <img src={greenCheck} alt='checkmark'  className="w-12 h-12 relative bottom-10 left-28" style={{marginBottom: '-45px'}}/>}
                <h3 className="font-bold text-2xl">Yearly</h3>
                <p className="text-4xl py-4">${paymentY}</p>
                { numberOfGuides > 0 ?(<p>(${(paymentY / 12).toFixed(2)} Per Month)</p>) :  (<p>(${paymentY} Per Month)</p>) }
              </button> 
             {/* </select>  */}
          </div>
        </div> 
        <div>
          <p className="font-bold">
            {/* Your subscription will cost ${paymentM}, {formState?.paymentInterval} */}
          </p> 
           {/* <p className="text-white font-bold text-xl">Your Subscription will start on {formatDate(startDate)} and end on {formatDate(endDate)}</p>  */}
        </div>
        <StripePage />
        {/* <a href="https://buy.stripe.com/test_dR66sb9KC6YQ87u144" className="text-3xl text-white bg-cyan-700">stripe</a> */}
      </div>

      
    </div>
  )
}

export default AddSubscription;