import React from "react";
import { useStoreContext } from '../../../utils/GlobalState';
import { formatDate } from '../../../utils/dateFormat';
import { DELETE_SUB } from '../../../utils/actions';
import AddSubscription from './addSubscription';
import NoSubPage from "./noSubPage";

function Subscription() {
  const [state, dispatch] = useStoreContext();

  // let price = state?.user?.subscription[0]?.price;
  // let formattedPrice = price /= Math.pow(10, 2);

  const handleDeleteSubscription = async (event) => {
    try {
      const subsriptionId = event.target.id;
      console.log(subsriptionId);
      dispatch({
        type: DELETE_SUB,
        id: subsriptionId,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const payment = parseInt(state?.user?.subscriptionPrice) * .01;
  console.log(payment) 

  return(
    <div className="m-auto py-5 font">
    <div className="bg-white m-auto rounded-xl w-5/6 mt-5">
      
      {state?.user?.subscriptionStatus === 'itWorked' ? (<>
      <div className=" justify-between border-b-2 font-bold">
        <h2>Subscription</h2>
        <p>{formatDate(state?.user?.subscriptionNextPaymentDate)}</p>
        <p>Your subscription is ${payment} a month</p>
      </div>
      </>
      ): (
        <AddSubscription />
      )}

    </div>
    <button className=" my-4 p-3 rounded-full bg-cyan-400 text-white font-bold">Open Payment Portal</button>
    </div>
  );
};

export default Subscription;