import React, { useState, useEffect } from 'react'

import FindUser from './findUser'
import DeleteSub from './deleteSub'
import FindGuide from './findGuide'
import AllUsers from './allUsers'
import DeleteUser from './deleteUser'

import { useQuery } from '@apollo/client';
import { QUERY_USER } from "../../utils/queries";
import { SET_USER } from '../../utils/actions';

import { useStoreContext } from '../../utils/GlobalState'

const AdminIndex = () => {

  const [state, dispatch] = useStoreContext();
  const { loading, data } = useQuery(QUERY_USER);

  useEffect(() => {
    if (data) {
      dispatch({
        type: SET_USER,
        user: data.user,
      });
      if(data?.user?.email !== 'contact@vacanze.com'){
        window.location.assign('/');
      }
    }

  }, [data]);

  const [page, setPage] = useState('FindUser')

  return (
    <div>
      {data != undefined ? (      
      <>
      <div className="m-auto homeText py-4 border-b-2 my-4">
        <h2 className="text-4xl font-bold">Admin Page</h2>
        <div className="flex p-4 m-auto justify-around w-1/2">
          <button onClick={() => setPage('FindUser')} className="w-fit px-2 pb-1 pt-2 mainColor text-white font-bold rounded-md">Find User</button>
          <button onClick={() => setPage('AllUser')} className="w-fit px-2 pb-1 pt-2 mainColor text-white font-bold rounded-md">Find All Users</button>
          <button onClick={() => setPage('DeleteSub')} className="w-fit px-2 pb-1 pt-2 mainColor text-white font-bold rounded-md">Delete User's Subscription</button>
          <button onClick={() => setPage('DeleteUser')} className="w-fit px-2 pb-1 pt-2 mainColor text-white font-bold rounded-md">Delete User</button>
          <button onClick={() => setPage('FindGuide')} className="w-fit px-2 pb-1 pt-2 mainColor text-white font-bold rounded-md">Find Guide</button>
        </div>
      </div>
      {page === 'FindUser' && (<FindUser />)}
      {page === 'AllUser' && (<AllUsers />)}
      {page === 'DeleteSub' && (<DeleteSub />)}
      {page === 'DeleteUser' && (<DeleteUser />)}
      {page === 'FindGuide' && (<FindGuide />)}
      </>
    ) :  ( 
      <div className='h-screen text-8xl font-bold homeText pt-40 w-4/5 m-auto'>We are sorry, this page does not exist anymore</div>
    )}
    </div>
  )
}

export default AdminIndex;