import React, { useRef, useState } from "react";
import { useStoreContext } from '../../../utils/GlobalState';

function Settings() {

  const [state, dispatch] = useStoreContext();
  const user = state?.user;

  const [passwordModalActive, setPasswordModalActive] = useState(false);
  const [submit, setSubmit] = useState(false)

  const sendPasswordEmail = async () => {
    try {
      // const sentEmail = await fetch("http://localhost:3002/sendPasswordChange", {
      const sentEmail = await fetch("/sendPasswordChange", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
      });
      console.log(sentEmail);
    } catch(e) {
      console.log(e);
    }
  }

  const handlePasswordSubmit = () => {
      // setSubmit(true);
      console.log('valid email')
      sendPasswordEmail();
      setPasswordModalActive(false);
  }

  return(
    <div className="bg-white">
      <div className="w-5/6 m-auto pb-5 py-5">
        <div className="flex flex-wrap justify-between bg-white  border-2 rounded-lg p-5">
          <p className="bg-white font-bold p-2 w-full md:w-1/3">Change Login Info:</p>
          <div>
            <button onClick={() => setPasswordModalActive(true)} className="mainColor text-white font-bold p-2 my-2 rounded ">
              Change Login Password
            </button>
          </div>
        </div>
      </div>

      {passwordModalActive === true && (
        <div className="absolute top-0 w-screen h-screen z-10 grid content-center">
          <div className="absolute top-0 w-full h-full bg-gray-400 opacity-40 z-10">
          <p></p>
          </div>
          {submit === false ? (
          <div className="bg-white z-50 w-2/3 m-auto rounded-md p-3">
            <p className="w-5/6 m-auto">An email will be sent to the Email address associated with this account to change your password, please press submit to continue or Cancel if you do not want to change your password.</p>
            <div className="flex my-2 justify around ">
              <div className="w-1/3 ml-auto">
                <button onClick={() => setPasswordModalActive(false)} className="bg-red-600 font-bold text-white p-2 rounded w-2/3">Cancel</button>
              </div>
              <div className="w-1/3 mr-auto">
                <button onClick={handlePasswordSubmit} className="mainColor font-bold text-white p-2 rounded w-2/3">Submit</button>
              </div>
            </div>
          </div>
          ) : (
            <div className="bg-white z-50 w-2/3 m-auto rounded-md p-3">
            <p className="w-5/6 m-auto my-3">If an account exists for this email address, an email will be sent to your inbox with instructions on how to change your password.</p>
            <button onClick={() => {
              setSubmit(false);
              setPasswordModalActive(false);
            }} className="bg-cyan-600 font-bold text-white p-2 mb-2 rounded w-2/3">OK</button>
          </div>
          )}
          
        </div>
      )}
    </div>
  )
};
export default Settings;