import { React } from "react";
import { HiOutlineLightBulb, HiOutlineCursorClick } from 'react-icons/hi';
import { FaCheck } from "react-icons/fa";
import { BsMap } from "react-icons/bs";
import { ImPower } from "react-icons/im";
import { TbAward } from 'react-icons/tb'

const ContactUs = () => {

  return (
    <div className="md:pt-20 mainColor text-white mb-0">
      <section className="w-full md:w-3/5 flex flex-wrap justify-around m-auto md:pt-0 pb-5 md:pb-0">
        <article className="flex flex-wrap p-0 w-full pb-12">
          <h2 className="w-full text-6xl font-bold secondText pb-8 m-auto">About VACANZE</h2>
          <p className="text-2xl m-auto">Welcome to VACANZE, the ultimate companion for vacation rental owners! With our innovative app, you can enhance the guest experience and streamline your property management like never before. Discover why our app is the must-have tool for owners like you:</p>

        </article>
        {/* <div className="hidden md:block md:w-1/2 w-full h-full md:pl-20 relative bottom-8">
          <iframe height='350' width='600' src="https://www.youtube.com/embed/ekYtbpRBeF8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="block md:hidden md:w-1/2 mb-4 w-full h-full pl-3 md:pl-20">
          <iframe height='200' width='350' src="https://www.youtube.com/embed/ekYtbpRBeF8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div> */}
      </section>
      <svg className="hidden md:block curveSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 319"><path fill="#ffffff" fillOpacity="1" d="M0,96L48,85.3C96,75,192,53,288,42.7C384,32,480,32,576,48C672,64,768,96,864,112C960,128,1056,128,1152,122.7C1248,117,1344,107,1392,101.3L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>

    {/* <div className="relative bottom-60"> */}
    <div className="bg-white text-black">
      <div className="w-5/6 m-auto text-left max-w-[1000px]">
    
      </div>
      <section className="bg-white w-full text-black pb-6">
          <ImPower className="text-9xl secondText m-auto" />
          <h2 className="text-4xl font-bold py-3 homeText">Empowering Hosts</h2>
        <p className="w-full md:w-4/5 m-auto md:text-2xl px-3 md:px-0 homeText">
        At Vacanze Guides, we empower vacation rental owners to enhance their guest experience through innovative digital guidebooks. Our platform allows you to create detailed, personalized guides that provide your guests with all the essential information about their stay, accessible directly from their phones. We aim to simplify the communication process, ensuring that every guest has a seamless and enjoyable experience.
        </p>
      </section>
      <section className="bg-white w-full text-black pb-6">
          <BsMap className="text-9xl secondText m-auto" />
          <h2 className="text-4xl font-bold py-3 homeText">Our Journey</h2>
        <p className="w-full md:w-4/5 m-auto md:text-2xl px-3 md:px-0 homeText">
        Vacanze Guides was founded by a team of tech enthusiasts and hospitality experts who recognized the need for a better way to communicate with guests. We combined our skills and experiences to develop a SaaS solution that addresses common pain points in the vacation rental industry. Our mission is to bridge the gap between hosts and guests, making information easily accessible and enhancing the overall rental experience.
        </p>
      </section>
      <section className="bg-white w-full text-black pb-6">
          <FaCheck className="text-9xl secondText m-auto" />
          <h2 className="text-4xl font-bold py-3 homeText">Why Choose Us</h2>
        <p className="w-full md:w-4/5 m-auto md:text-2xl px-3 md:px-0 homeText">
        Choosing Vacanze Guides means opting for a hassle-free, efficient way to share important information with your guests. Our user-friendly platform helps you create comprehensive digital guidebooks that include everything from check-in procedures to local recommendations. By providing your guests with a single source of truth, you can reduce misunderstandings and improve their stay, leading to better reviews and repeat bookings. Trust us to help you elevate your hosting game.
        </p>
      </section>
      <section className="bg-white w-full text-black pb-6">
          <HiOutlineLightBulb className="text-9xl secondText m-auto" />
          <h2 className="text-4xl font-bold py-3 homeText">The Idea of VACANZE</h2>
        <p className="w-full md:w-4/5 m-auto md:text-2xl px-3 md:px-0 homeText">
          Between staying at vacation rentals and owning/managing them ourselves, we realized there was some disconnect between hosts and their guests.<span className="opacity-0">d</span>Guests don't want to have to call their host while on vacation, they want to enjoy thier time and not be worried.<span className="opacity-0">d</span>And many hosts have other jobs or multiple properites to manage and want to save time.<span className="opacity-0">d</span>With VACANZE, hosts can give guests all the information they want and need while saving time and removing unnecessary communications.<span className="opacity-0">d</span>VACANZE makes it easier for your guests to enjoy their stays, and makes manging properties hassle free for hosts.
        </p>
      </section>
      <div className="bg-white">
      <svg className="hidden md:block curveSvg" viewBox="0 0 1440 320"><path fill="#1e4259" fill-opacity="1" d="M0,96L120,90.7C240,85,480,75,720,69.3C960,64,1200,64,1320,64L1440,64L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
      </div>
      <section className="mainColor text-white pb-12 relative bottom-4 mt-[-20px]">
      <HiOutlineCursorClick className="m-auto text-9xl text-white" />
        <h2 className="text-4xl font-bold py-3">Making managing your properites easier</h2>
        <p className="w-full px-3 md:px-0 md:w-3/5 m-auto md:text-2xl">A digital welcome book offers many advantages over traditional paper welcome books. Not only is it more environmentally friendly, but it's also more convenient and accessible for guests. With a digital welcome book, your guests can access all the information they need from their smartphone or tablet, and you can easily update and add new information as needed.</p>
      </section>
      <section className="bg-white py-5 pb-12">
      <TbAward className="m-auto text-9xl homeText" />
        <h2 className="text-4xl font-bold py-3 homeText">Setting Yourself Apart From the Competition</h2>
        <p className="w-full px-3 md:px-0 md:w-3/5 m-auto text-xl homeText">By embracing technology and offering a custom digital welcome book, you'll set your property apart from the competition and provide your guests with a truly memorable experience. Join the future of vacation rentals and start creating your digital welcome book today!</p>
      </section>
      </div>
    </div>
  )
}

export default ContactUs;