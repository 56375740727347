
import { useStoreContext } from "../../utils/GlobalState";
import { useMutation } from "@apollo/client";
import { UPDATE_SUB_NAME } from "../../utils/mutations";

function StripePage() {
  const [state] = useStoreContext();
  const [updateSubName] = useMutation(UPDATE_SUB_NAME);

  // const id = parseInt(state?.order);
// eslint-disable-next-line
  const handleSendEmail = async (userData) => {
    try {
      const sentEmail = await fetch("http://localhost:3002/sendConfirmSubscription", {
        // const sentEmail = await fetch("/sendConfirmSubscription", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData)
      });
      console.log(sentEmail);
    } catch(e) {
      console.log(e);
    }
  }
  
  const handleClick = async() => {
    // const mutationResponse = await updateSubName({
    //   variables: {
    //     numberOfGuides: state?.order?.numberOfGuides,
    //     interval: state?.order?.interval
    //   }
    // });
    console.log(state.order);

    // fetch("http://localhost:3002/create-checkout-session", {
    fetch("/create-checkout-session", {
    method: "POST",
    headers: {
      "Content-type": "application/JSON"
    },
    body: JSON.stringify({
        interval: state.order.interval,
        numberOfGuides: state.order.numberOfGuides,
        userEmail: state?.user?.email
    })
  }).then(res => {
    if(res.ok) {
      console.log(res);
      // handleSendEmail(state?.user)
      return res.json();
    } 
    return res.json().then(json => Promise.reject(json));
  }).then(async({ url }) => {

    // if(addedSub) {
    //   handleSendEmail(addedSub);
    //   }
    window.location = url;
  }).catch(e => {
    console.log(e);
  }) 
  }

  return (
  <div>
    {!state?.order?.numberOfGuides ? (<button onClick={() => handleClick()} disabled className='mainColor text-white text-2xl font-bold rounded-md shadow-lg m-5 py-5 px-10 opacity-80'>select your options first</button>) : (<button onClick={() => handleClick()} className='mainColor text-white text-2xl font-bold rounded-md shadow-lg m-5 pt-2 w-1/2'>Go to Checkout</button>)}
  </div>
  )
};

export default StripePage;