import React, { useState, useEffect } from "react";
import { useStoreContext } from '../../../utils/GlobalState';
import { formatUnixDate } from '../../../utils/dateFormat';
import ManageSub from "./manageSub";
import UpgradeSub from "./upgrade";

const ActiveSubPage = () => {

  const [state] = useStoreContext();
  const user = state?.user;
  console.log(user)
  
  

  const [manageSub, setManageSub] = useState(false)
  const [upgradeSub, setUpgradeSub] = useState(false)
  const [payment, setPayment] = useState(state?.user?.subscriptionPrice)

  useEffect(() => {
    setPayment(user?.subscriptionPrice)
  }, [state]);
  console.log(payment)
  return (
    <>
    {user?.subscriptionStatus === "active" ? (
      <div className="w-full md:w-5/6 m-auto p-5 rounded-xl justify-between border-2 font-bold homeText">
        <div className="w-full border-b-2 m-auto flex flex-wrap md:flex-nowrap justify-between pb-1">
          <h2 className="w-full md:w-auto pr-3 text-center md:text-left font-bold text-2xl md:text-xl border-b-2 md:border-b-0 mb-2">Current Subscription</h2>
          <div className=" w-full md:w-auto flex md:block">
          <button onClick={() =>setUpgradeSub(true)} className="mainColor rounded-lg px-2 pt-1 mr-2 text-white">Upgrade Subscription</button>
            <button onClick={() => setManageSub(true)} className="bg-gray-300 mr-1 md:mr-2 pt-1 px-2 rounded-lg mb-1">Contact Support</button>
            {/* <a className="mainColor text-white pt-1 px-2 rounded-lg mb-1">Upgrage Subscription</a> */}
          </div>
          
        </div>
        
        <div className="flex flex-wrap mt-5 text-xl">
          <div className="flex w-full justify-between ">
            <p>{user?.subscriptionName} Guide Subscription</p>
            <p className=" px-2  pt-1  rounded-full">${payment}/{user?.subPayInterval}</p>
            <div className="w-0 md:w-3/5"></div>
          </div>
          <div className="flex flex-wrap w-full justify-between mt-5">
            <p className=""><span className="">Subscription Start Date: </span>{user?.subStartDate}</p>
          
          {user?.subCancelAtEndofPeriod === "tru" ? (
            <div>
              <p><span>Subscription End Date: </span>{user?.subscriptionNextPaymentDate}</p>
            </div>
          ) : (
            <>
              <p><span>Next Payment Due: </span>{user?.subscriptionNextPaymentDate}</p>
              <p className="w-1/2"></p>
            </>
            
          )}
          
          </div>
          
        </div>
        {manageSub && <ManageSub setManageSub={setManageSub}/>}
        {upgradeSub && <UpgradeSub setUpgradeSub={setUpgradeSub} setPayment={setPayment} />}
      </div>
    ) : (
      <div className="m-6 mb-12 md:m-12">
        <p className="font-bold text-xl">You do not currently have an active subscription.  To add one, click the button below</p>
        <div className="mt-4">
          <a href="/dashboard/addSubsription" className="mainColor secondText font-bold p-2 px-4 pt-3 rounded-lg">Subscribe</a>
        </div>
      </div>
    )}
    </>
  )
}

export default ActiveSubPage;